h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  color: var(--#{$prefix}dark);
  font-family: var(--#{$prefix}font-heading);
}

hr {
  opacity: 1;
  height: 1px;
  border: none;
  margin: 25px 0;
  background-color: var(--#{$prefix}border-color);
}

mark {
  padding: 0;
  color: var(--#{$prefix}primary);
  background-color: transparent;
}

// h1 a,
// h2 a,
// h3 a,
// h4 a,
// h5 a,
// h6 a {
//   color: inherit;
// }

// h1 {
//   font-size: 50px;
// }

// h2 {
//   font-size: 40px;

//   @media #{$xs} {
//     font-size: 31px;
//   }
// }
// h3 {
//   font-size: 30px;
// }
// h4 {
//   font-size: 25px;
// }
// h5 {
//   font-size: 20px;
// }
// h6 {
//   font-size: 16px;
// }

a {
  outline: 0;
  display: inline-block;
  // display: block;
  text-decoration: none;

  &:hover {
    color: $primary;
    @include transition(0.3s);
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    outline: 0;
    text-decoration: none;
  }
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  //   margin: 0px;
  //   color: $text-color;

  //   font-size: 16px;
  //   font-weight: 400;
  //   line-height: 26px;

  //   @media #{$lg} {
  //     font-size: 16px;
  //     line-height: 26px;
  //   }
  //   @media #{$xs} {
  //     font-size: 16px;
  //     line-height: 26px;
  //   }

  //   a {
  //     position: relative;

  //     &:after {
  //       background: none repeat scroll 0 0 transparent;
  //       bottom: 0;
  //       content: "";
  //       display: block;
  //       height: 1px;
  //       left: 50%;
  //       position: absolute;
  //       background: $theme-color;
  //       transition: width 0.3s ease 0s, left 0.3s ease 0s;
  //       width: 0;
  //     }
  //     &:hover:after {
  //       width: 100%;
  //       left: 0;
  //     }
  //   }
}

[class*="unordered_list"] {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > li {
    // float: left;
    display: inline-block;
    list-style: none;
  }
}

.unordered_list_block {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  > li {
    width: 100%;
    display: block;
  }
}

blockquote {
  padding: 60px;
  display: flex;
  margin-bottom: 40px;
  border-radius: var(--#{$prefix}border-radius);
  background-color: var(--#{$prefix}primary-bg-subtle);

  .quote_icon {
    flex: 0 0 50px;
    margin: 0 30px 0 0;
  }

  span {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
    color: var(--#{$prefix}dark);
    font-family: var(--#{$prefix}font-heading);
  }

  cite {
    font-size: 18px;
    line-height: 28px;
    color: var(--#{$prefix}body-color);
  }
}

@for $i from 1 to 8 {
  .line-clamp-#{$i} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i; /* number of lines to show */
    line-clamp: 1;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-content {
  .single-posts-wrapper {
    ul,
    ol {
      margin-left: 0;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-inline-start: 2.5rem;
      line-height: 2rem;
    }

    ol {
      list-style-type: decimal;
    }
  }
}
