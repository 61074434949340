/*===========================
    01. Font CSS
===========================*/

/* Loading Museo Font */

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../assets/fonts/Museo/Museo300-Regular.otf") format("otf"),
    url("../../assets/fonts/Museo/Museo300-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Museo/Museo300-Regular.woff") format("woff");
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../assets/fonts/Museo/Museo100-Regular.otf") format("otf"),
    url("../../assets/fonts/Museo/Museo100-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Museo/Museo100-Regular.woff") format("woff");
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../assets/fonts/Museo/Museo500-Regular.otf") format("otf"),
    url("../../assets/fonts/Museo/Museo500-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Museo/Museo500-Regular.woff") format("woff");
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../../assets/fonts/Museo/Museo700-Regular.otf") format("otf"),
    url("../../assets/fonts/Museo/Museo700-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Museo/Museo700-Regular.woff") format("woff");
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../assets/fonts/Museo/Museo900-Regular.otf") format("otf"),
    url("../../assets/fonts/Museo/Museo900-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Museo/Museo900-Regular.woff") format("woff");
}
