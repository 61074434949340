.countup-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 4rem;
    font-weight: bold;
  }
}

.count-up-section {
  background-color: #eff2f9;
}
