.expert-card {
  min-height: 320px;
  width: 288px;

  &:hover,
  &:active {
    filter: grayscale(100%) contrast(1) blur(0);
  }

  .image-wrapper {
    border-radius: 24px;
    height: 100%;
  }
}

.carousel-control-next {
  right: -180px;
}

.panel-box {
  border: 1px solid #999;
  border-radius: 2rem;
  padding: 1rem;
  position: relative;
}

.psychologists-card {
  position: relative;
  box-shadow: 0 1rem 1.75rem 0 rgba(45, 55, 75, 0.1);
  width: 100%;

  border: 0.0625rem solid rgba(220, 224, 229, 0.6);
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  height: 100%;

  span {
    color: #292dc2;
  }

  img {
    border-top-left-radius: 0.23rem;
    border-top-right-radius: 0.23rem;
  }

  &-text {
    position: relative;

    > span {
      color: #292dc2;
      font-size: 13px;
      padding-right: 5px;
    }

    h4 {
      line-height: normal;
      margin-bottom: 15px;
    }

    .meta-style2 {
      border-top: 1px dashed #cee1f8;
      padding-top: 15px;

      ul li {
        margin-bottom: 0;
        font-weight: 500;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        margin: 5px 10px 5px 0;

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 14px;
          font-weight: 600;
          margin-right: 5px;
        }
      }
    }

    p {
      font-weight: 400;
      padding: 0;
    }
  }
}

.search-results .psychologist__title {
  font-weight: 600;
  font-size: 24px;
  color: #2bc88b;
  margin: 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-results .psychologist__subtitle {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: 0 0 14px;
}
// span {
//   font-style: inherit;
//   font-weight: inherit;
// }

.blog-list-left-heading:after,
.blog-title-box:after {
  content: "";
  height: 2px;
}

.blog-grid-simple-content a:hover {
  color: #1d184a;

  &:after {
    color: #1d184a;
  }
}

.blog-grid-text {
  position: relative;

  > span {
    color: #292dc2;
    font-size: 13px;
    padding-right: 5px;
  }

  h4 {
    line-height: normal;
    margin-bottom: 15px;
  }

  .meta-style2 {
    border-top: 1px dashed #cee1f8 !important;
    padding-top: 15px;

    ul li {
      margin-bottom: 0;
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      margin: 5px 10px 5px 0;

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 14px;
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }

  p {
    font-weight: 400;
    padding: 0;
  }
}

.mt-6,
.my-6 {
  margin-top: 3.5rem;
}

.prev-arrow,
.next-arrow {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  padding: 1.5rem 2rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 3rem;
  line-height: 3rem;
  background: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  transform-origin: 50% 50%;
  transform: translateY(-50%);
  transition: color 200ms, background 200ms;
}
.prev-arrow:hover,
.next-arrow:hover,
.prev-arrow:focus,
.next-arrow:focus {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(0, 0, 0, 0.5);
}
.prev-arrow {
  left: 5%;
  z-index: 1000;
}
.prev-arrow:before {
  content: "❮";
}
.next-arrow {
  right: 5%;
}
.next-arrow:after {
  content: "❯";
}

.team_item {
  overflow: hidden;
  transition: var(--#{$prefix}transition);
  background-color: var(--#{$prefix}white);
  border-radius: var(--#{$prefix}border-radius);
  box-shadow: 0px 8px 30px 0px rgba(43, 171, 160, 0.07);

  &:hover {
    box-shadow: var(--#{$prefix}box-shadow);
  }

  .blog-image-wrap {
    display: block;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      display: block;
      transition: var(--#{$prefix}transition);

      max-height: 480px;
      object-fit: contain;
    }
  }

  .team_memder_info {
    padding: 30px 30px 40px;
  }

  .team_memder_designation {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 15px;
    color: var(--#{$prefix}primary);
  }

  .team_memder_name {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
