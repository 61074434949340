//----------------------------------------------------------//
//	BREADCRUMB
//----------------------------------------------------------//

@import "../variables";
@import "../utilities/mixin";

.breadcrumb {
  background-color: transparent;

  > li {
    font-weight: 600;
    color: var(--#{$prefix}dark);

    font-family: var(--#{$prefix}font-heading);
    font-size: var(--#{$prefix}body-font-size);
    line-height: var(--#{$prefix}body-line-height);

    &:not(:last-child) {
      position: relative;
      // padding-right: 36px;
    }
  }

  a {
    // display: block;
    color: var(--#{$prefix}primary);

    &:hover {
      color: var(--#{$prefix}secondary);
    }
  }
}

.page-breadcrumb {
  nav {
    ol {
      background: none;
      padding: 0;

      li {
        color: rgba($white, 0.7);
        font-size: 20px;
        font-weight: 500;

        &::before {
          color: $white;
        }

        &.active {
        }

        a {
          color: $white;
        }
      }
    }
  }
}
