//----------------------------------------------------------//
//	BANNER
//----------------------------------------------------------//

.page-banner-section-style1 {
  margin-top: 107px;

  @media #{$md} {
    margin-top: 94px;
  }
}

.page-banner-section-style2 {
  a.h1 {
    margin: 0 0;
  }
}

.page-banner-section-style2.decoration-wrapper {
  z-index: 1;
  position: relative;
  .decoration-item {
    z-index: -1;
    position: absolute;
  }
}

.page-banner-section-style2 {
  overflow: hidden;
  // padding: 55px 0 62px;
  background-color: #f4fcfa;
  // background-color: $primary-bg-subtle;
  // background-color: var(--#{$prefix}primary-bg-subtle);

  .decoration-item {
    top: 50%;
    transform: translateY(-50%);

    &.shape_leaf_1 {
      left: 0;
    }

    &.shape_leaf_2 {
      right: 0;
    }
  }
}

// .site_header + main > .page_banner {
//   margin-top: 118px;
// }

// .page_title {
//   font-size: 54px;
//   font-weight: 800;
//   line-height: 60px;
//   margin-bottom: 30px;
// }
