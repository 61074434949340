/*===== All Section Title Style =====*/

.page-section {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;

  @media #{$md} {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
}

.page-section-lg {
  padding-top: 4.5rem !important;
  padding-bottom: 5rem !important;

  @media #{$lg} {
    padding-top: 6.25rem !important;
    padding-bottom: 6.875rem !important;
  }

  @media #{$laptop} {
    padding-top: 9.375rem !important;
    padding-bottom: 10rem !important;
  }
}

.inner-container {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  &.left {
    margin-left: 0px;
  }
}

.inner-container-small {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;

  &.left {
    margin-left: 0px;
  }
}

%section-header1 {
  text-align: center;
  //padding-bottom: 70px;

  h2 {
    position: relative;

    //margin-bottom: 15px;  // used condition if title description shown

    //color: $heading-color;

    //font-size: 32px;
    //font-weight: 700;
    //line-height: 55px;
    //@include font-source(32px, 700, 55px, $heading-color);
    @include font-source(false, 700, 45px);

    @media #{$xs} {
      line-height: 41px;
    }
  }
  p {
    margin: 0 auto 0 auto;

    //font-size: 18px;

    @media (min-width: 1199px) {
      max-width: 60%;
    }
  }
  span {
    margin-bottom: 0.25rem;

    //color: $primary-color;

    //font-size: 25px;
    //font-weight: 700;
    @include font-source(25px, 700, false, $primary-color);

    @media #{$xs} {
      font-size: 20px;
    }
  }
}

%section-header2 {
  text-align: center;
  //padding-bottom: 70px;
  padding-top: 30px;
  position: relative;

  h2 {
    //position: relative;

    //margin-bottom: 1rem;

    //color: $heading-color;

    //font-size: 32px;
    //font-weight: 700;
    //line-height: 55px;
    //@include font-source(32px, 700, 55px, $heading-color);
    @include font-source(false, 700, 45px);

    @media #{$xs} {
      line-height: 41px;
    }
  }

  p {
    margin: 0 auto 0 auto;

    //font-size: 18px;

    //@media (min-width: 1199px) {
    //  max-width: 60%;
    // }
  }
  span {
    position: absolute;
    top: 46px;
    color: rgba(14, 29, 52, 0.06);
    left: 0;
    right: 0;
    z-index: 1;
    font-weight: 700;
    font-size: 56px;
    text-transform: uppercase;
    line-height: 0;
  }
}

%section-header-line {
  content: "";
  width: 50px;
  height: 2px;
  background: $primary-color;
  display: inline-block;
}

%section-header1 {
  @extend %section-header1;
  h2 {
    &:before {
      @extend %section-header-line;
      margin: 0 15px 10px 0;
    }
    &:after {
      @extend %section-header-line;
      margin: 0 0 10px 15px;
    }
    @media (max-width: 768px) {
      &:before,
      &:after {
        display: none !important;
      }
    }
  }
}

%section-header2 {
  @extend %section-header2;
}

// .section-header {
//   @extend %section-header2;

//   p {
//     @media (min-width: 1199px) {
//       max-width: 60%;
//     }
//   }
// }

// .section-content-title {
//   @extend %section-header2;
// }

// .section-content {
//   p {
//     line-height: 2rem;
//   }
//   ul {
//     line-height: 2rem;
//   }
// }

%policy {
  //background: #ededed url(../images/shattered1.png);
  padding: 86px 20px 40px;
}

%policy-content {
  background: $white;
  border-radius: 2px;
  padding: 45px;
  margin: 0 auto;
}

.section-privacy-policy {
  @extend %policy;
  .container {
    @extend %policy-content;
  }
}

.section-offer-contract-policy {
  @extend %policy;
  .container {
    @extend %policy-content;
  }
}

/**
   *	Typography Style
   **/

.site-content {
  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  //ol {
  //    list-style-type: upper-roman;
  //    list-style-position: inside;

  //  > h2 {
  //    &::before {
  //     padding-right: 0.3em;
  //    }
  // }
  //}

  p {
    margin-top: 0;
    margin-bottom: 1rem;

    text-align: justify;

    line-height: 2rem;

    span {
      font-weight: bold;
    }
  }

  //ol {
  //list-style: none;
  // counter-reset: li;
  // padding-left: 1rem;
  // text-align: justify;
  // li {
  //  &:before {
  //    counter-increment: li;
  //    content: counters(li, ".") ". ";
  // }
  // }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  // }

  /*ol,
  ul {
    li {
      padding: 1.25rem 0 0;

      font-size: 1rem;

      //font-weight: 500;
      line-height: 1.75rem;

      letter-spacing: -0.03125rem;

      ol,
      ul {
        padding-top: 0.3125rem;

        li {
          padding: 0;

          //font-size: 0.875rem;
          line-height: 1.5rem;

          letter-spacing: -0.03125rem;
        }
      }
    }
  } */
}

.content-policy {
  .title {
  }

  .subtitle {
    text-align: right;
    font-style: italic;
  }
}
