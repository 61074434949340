/*===========================
    03. FAQ CSS
===========================*/

@import "../variables";

.accordion .accordion-item {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $gray-200;

  .accordion-button {
    font-size: 1.5rem;
    font-weight: 700;
    box-shadow: none;
    line-height: 30px;
    padding: 24px 0 23px;
    color: var(--#{$prefix}dark);
    border-radius: 0 !important;
    background-color: transparent;

    &:not(.collapsed) {
      color: var(--#{$prefix}primary);
    }
  }

  .accordion-body {
    padding: 0 0 22px;
  }
}

@media screen and (max-width: 1440px) {
  .accordion .accordion-item {
    .accordion-button {
      font-size: 1.25rem;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 991px) {
  .accordion .accordion-item {
    .accordion-button {
      padding: 20px 0 19px;
    }
  }
}

.accordion-style {
  &.theme-accordion {
    .single-accordion {
      .accordion-btn {
        button {
          background: linear-gradient(to left, #3763eb 0%, #6f58e8 100%);
          color: $white;

          &::after {
            color: $white;
          }
        }
      }
    }
  }
}

.section-faq-style3 {
  .faq-page-nav {
    padding-right: 30px;
    .title {
      margin-bottom: 14px;
    }
    .nav-tabs {
      border: none;
      display: block;
      .nav-item {
        margin: 18px 0;
      }
      .nav-link {
        border: none;
        background-color: transparent;
        padding: 5px 0;
        color: var(--color-heading);
        font-weight: 600;
        font-family: var(--font-secondary);
        position: relative;
        transition: var(--transition);
      }
    }
  }
}
