/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
@import "variables";

%features-item {
  h3 {
    font-weight: 700;
    //font-size: 26px;
    //color: $color_1;
    // margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    // list-style: none;
    // padding: 0;
    margin-bottom: 1rem;

    li {
      padding-bottom: 0.5rem;
      &:last-child {
        padding-bottom: 0;
      }
    }

    i {
      font-size: 20px;
      padding-right: 4px;
      color: $primary-color;
    }
  }
}

.details-overview-section {
  // padding-top: 80px;

  .features-item + .features-item {
    margin-top: 100px;

    @media #{$sm} {
      margin-top: 40px;
    }
  }

  .features-item {
    @extend %features-item;
  }
}
