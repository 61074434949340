.nav-tabs {
  margin-bottom: $spacer;
  .nav-item {
    margin: {
      right: ($spacer * 0.375);
      bottom: $spacer * 0.375;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.justify-content-center .nav-item {
    margin: {
      right: ($spacer * 0.1875);
      left: ($spacer * 0.1875);
    }
  }
  &.justify-content-end .nav-item {
    margin: {
      right: 0;
      left: ($spacer * 0.375);
    }
  }
  &.flex-column {
    .nav-item {
      margin: {
        right: 0;
        left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .nav-link {
    @include border-radius(var(--#{$prefix}nav-tabs-border-radius));
    // font-weight: $nav-tabs-link-font-weight;
  }
}

//
// Tabs
//

.nav-tabs {
  $nav-tabs-border-radius: 30px;
  $nav-tabs-link-active-color: $primary;
  $nav-tabs-link-active-bg: transparent;
  $nav-tabs-link-active-border-color: $primary;
  //   $nav-tabs-border-width: none;

  // scss-docs-start nav-tabs-css-vars
  --#{$prefix}nav-tabs-border-width: #{$nav-tabs-border-width};
  --#{$prefix}nav-tabs-border-color: #{$nav-tabs-border-color};
  --#{$prefix}nav-tabs-border-radius: #{$nav-tabs-border-radius};
  --#{$prefix}nav-tabs-link-hover-border-color: #{$nav-tabs-link-hover-border-color};
  --#{$prefix}nav-tabs-link-active-color: #{$nav-tabs-link-active-color};
  --#{$prefix}nav-tabs-link-active-bg: #{$nav-tabs-link-active-bg};
  --#{$prefix}nav-tabs-link-active-border-color: #{$nav-tabs-link-active-border-color};
  // scss-docs-end nav-tabs-css-vars

  border-bottom: none;

  .nav-link {
    margin-bottom: calc(
      -1 * var(--#{$prefix}nav-tabs-border-width)
    ); // stylelint-disable-line function-disallowed-list
    border: var(--#{$prefix}nav-tabs-border-width) solid transparent;
    @include border-top-radius(var(--#{$prefix}nav-tabs-border-radius));

    &:hover,
    &:focus {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
      border-color: var(--#{$prefix}nav-tabs-link-hover-border-color);
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: var(--#{$prefix}nav-tabs-link-active-color);
    background-color: var(--#{$prefix}nav-tabs-link-active-bg);
    border-color: var(--#{$prefix}nav-tabs-link-active-border-color);
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: calc(
      -1 * var(--#{$prefix}nav-tabs-border-width)
    ); // stylelint-disable-line function-disallowed-list
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}

//
// Button Sizes
//

// .btn {
//   // scss-docs-start btn-css-vars
//   --#{$prefix}btn-padding-x: #{$btn-padding-x};
//   --#{$prefix}btn-padding-y: #{$btn-padding-y};
//   --#{$prefix}btn-font-family: #{$btn-font-family};
//   @include rfs($btn-font-size, --#{$prefix}btn-font-size);
//   --#{$prefix}btn-font-weight: #{$btn-font-weight};
//   --#{$prefix}btn-line-height: #{$btn-line-height};
//   --#{$prefix}btn-color: #{$btn-color};
//   --#{$prefix}btn-bg: transparent;
//   --#{$prefix}btn-border-width: #{$btn-border-width};
//   --#{$prefix}btn-border-color: transparent;
//   --#{$prefix}btn-border-radius: #{$btn-border-radius};
//   --#{$prefix}btn-hover-border-color: transparent;
//   --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
//   --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
//   --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), 0.5);
//   // scss-docs-end btn-css-vars
// }

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-border-radius-sm
  );
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}
