// Import Bootstrap functions to use inside variables values
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// Theme : https://smarty.stepofweb.com/5.0.7/html_frontend/footer-option-dark.html

// Prefix for CSS variables and icons

$prefix: pw- !default;
// $icon-prefix: pi- !default;

// Colors
//
// Gray and brand colors to use across the Theme.

// $gray: #eff2f9;
// $gray-100: #f8f9fa !default;
// $gray-200: #f8f5f0 !default;
// $gray-300: #dfd7ca !default;
// $gray-400: #ced4da !default;
// $gray-500: #98978b !default;
// $gray-600: #8e8c84 !default;
// $gray-700: #495057 !default;
// $gray-800: #3e3f3a !default;
// $gray-900: #212529 !default;

$white: #fff !default;
$gray: #869ab8;
$gray-dark: #384c74;
$gray-100: #f9fbfd;
$gray-200: #f1f4f8;
$gray-300: #d9e2ef;
$gray-400: #c6d3e6;
$gray-500: #abbcd5;
$gray-600: #869ab8;
$gray-700: #506690;
$gray-800: #384c74;
$gray-900: #1b2a4e;
$black: #000 !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2 !default;

// Disable Bootstrap's default $colors map
$colors: () !default;

// Theme colors

$blue: #325d88 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #f47c3c !default;
$yellow: #ffc107 !default;
$green: #93c54b !default;
$teal: #20c997 !default;
$cyan: #29abe0 !default;

$primary: $blue !default;
// $secondary: $gray-200 !default;
$secondary: #f3f6ff !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-800 !default;

// Create your own map

$custom-colors: (
  // "white": $white,
  // "black": $black,
  "custom-color": #900
);

// Body

$body-color: $gray-800 !default;

// Links

$link-color: $success !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: "Museo", "Roboto", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-root: 1rem !default;
$font-size-base: $font-size-root !default;
$font-size-xl: calc(var(--#{$prefix}body-font-size) * 1.25) !default;
$font-size-lg: calc(var(--#{$prefix}body-font-size) * 1.125) !default;
$font-size-sm: calc(var(--#{$prefix}body-font-size) * 0.875) !default;
$font-size-xs: calc(var(--#{$prefix}body-font-size) * 0.75) !default;

$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.4 !default;
$line-height-lg: 1.6 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Modifying default Bootstrap"s $font-sizes map
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  "xl": $font-size-xl,
  "lg": $font-size-lg,
  "base": $font-size-base,
  "sm": $font-size-sm,
  "xs": $font-size-xs,
) !default;

$headings-font-weight: 400 !default;

// Dropdowns

$dropdown-link-color: $gray-600 !default;
$dropdown-link-hover-color: $dropdown-link-color !default;
$dropdown-link-hover-bg: $gray-200 !default;
$dropdown-link-active-color: $dropdown-link-color !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;

// Nav

$nav-link-padding-x: 0.9rem !default;
$nav-link-disabled-color: $gray-300 !default;
$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-link-hover-border-color: $gray-300 !default;
$nav-tabs-link-active-bg: $white !default;
$nav-pills-link-active-color: $gray-600 !default;
$nav-pills-link-active-bg: $gray-200 !default;

// Navbar

$navbar-padding-y: 0rem;
// $navbar-padding-y: $spacer * 0.75 !default;
$navbar-padding-x: 0rem;

// $navbar-transition: background-color 0.1s ease-in-out !default;

// $navbar-nav-link-padding-x: 1rem !default;
// $navbar-nav-link-font-size: $font-size-base;
// $navbar-nav-link-font-weight: $font-weight-normal;
// $navbar-nav-link-text-transform: capitalize;

// Brand

$navbar-brand-font-size: 1.375rem !default;
$navbar-brand-font-weight: $font-weight-semibold !default;
// $navbar-brand-padding-y:                    $spacer * .5 !default;

// Pagination

$pagination-color: $gray-600 !default;
$pagination-bg: $gray-200 !default;
$pagination-border-color: $gray-200 !default;
// $pagination-hover-color: $pagination-color !default;
$pagination-active-color: $white !default;
$pagination-active-bg: $primary !default;
// $pagination-active-border-color: $gray-300 !default;
// $pagination-disabled-color: $gray-300 !default;
// $pagination-disabled-bg: $gray-200 !default;
// $pagination-disabled-border-color: $pagination-border-color !default;

// $pagination-padding-y: 0.7rem;
// $pagination-padding-x: 1rem;
// $pagination-padding-y-sm: 0.25rem;
// $pagination-padding-x-sm: 0.5rem;
// $pagination-padding-y-lg: 0.75rem;
// $pagination-padding-x-lg: 1.5rem;

// $pagination-color: var(--#{$prefix}gray-600);
// $pagination-bg: $white;
// $pagination-border-width: $border-width;
// $pagination-border-radius: $border-radius;
// $pagination-border-color: var(--#{$prefix}gray-300);

// $pagination-focus-box-shadow: $input-btn-focus-box-shadow;
// $pagination-focus-outline: 0;
// $pagination-focus-color: var(--#{$prefix}link-hover-color);
// $pagination-focus-bg: var(--#{$prefix}gray-200);

// $pagination-hover-color: var(--#{$prefix}white);
// $pagination-hover-bg: $primary;
// $pagination-hover-border-color: $primary;

// $pagination-active-color: $component-active-color;
// $pagination-active-bg: $component-active-bg;
// $pagination-active-border-color: $pagination-active-bg;

// $pagination-disabled-color: var(--#{$prefix}gray-400);
// $pagination-disabled-bg: $white;
// $pagination-disabled-border-color: var(--#{$prefix}gray-300);

// Cards

$card-border-color: rgba($gray-300, 0.75) !default;
$card-cap-bg: rgba($gray-200, 0.25) !default;

// Popovers

$popover-header-bg: $gray-200 !default;

// Modals

$modal-content-border-color: $gray-300 !default;
$modal-header-border-color: $modal-content-border-color !default;

// Progress bars

$progress-bg: $gray-300 !default;
$progress-bar-color: $white !default;
$progress-border-radius: 10px !default;

// List group

$list-group-border-color: $gray-300 !default;
$list-group-hover-bg: $gray-200 !default;
$list-group-active-color: $body-color !default;
$list-group-active-bg: $gray-200 !default;
$list-group-active-border-color: $gray-300 !default;
$list-group-disabled-color: $gray-500 !default;
$list-group-disabled-bg: $white !default;
$list-group-action-color: $list-group-active-color !default;
$list-group-action-active-color: $list-group-active-color !default;
$list-group-action-active-bg: $gray-300 !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem !default;
$breadcrumb-padding-x: 0.75rem !default;
$breadcrumb-bg: $pagination-bg !default;
$breadcrumb-border-radius: 0.25rem !default;
$breadcrumb-margin-bottom: 0 !default;

// Accordion

$accordion-padding-y: 10rem;
$accordion-padding-x: 10.25rem;
$accordion-color: $body-color;
// $accordion-bg: "red";
// $accordion-border-width: $border-width;
$accordion-border-color: rgba($black, 0.125);
// $accordion-border-radius: $border-radius;
// $accordion-inner-border-radius: subtract(
//   $accordion-border-radius,
//   $accordion-border-width
// );

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
// $accordion-button-bg: $accordion-bg;
// $accordion-transition: $btn-transition, border-radius 0.15s ease;
// $accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $accordion-button-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);

//$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M6 12L18 12'/></svg>");
$accordion-button-icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 12H15' stroke='%23323232' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 9L12 15' stroke='%23323232' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z' stroke='%23323232' stroke-width='2'/%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 12H15' stroke='%23323232' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z' stroke='%23323232' stroke-width='2'/%3E%3C/svg%3E");

// Close

// $btn-close-color: $white !default;
$btn-close-color: $dark;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
// $btn-close-focus-shadow: $input-btn-focus-box-shadow;
$btn-close-opacity: 0.8 !default;
$btn-close-hover-opacity: 1 !default;

// Components, borders

$border-radius: 24px;
$border-radius-sm: 6px;
$border-radius-lg: 30px;
$border-radius-xl: 80px 0 80px 0;
$border-radius-xxl: 150px 0 150px 0;
$border-radius-pill: 100%;

$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 0.75rem;
$border-radius-xxl: 2rem;
$border-radius-2xl: $border-radius-xxl;
$border-radius-pill: 50rem;

// Buttons + Forms

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
// $input-btn-font-size: $font-size-base;
// $input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0;
// $input-btn-focus-color: rgba($component-active-bg, 0.25);
// $input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 0.8rem;
// $input-btn-font-size-sm: $font-size-xs;
// $input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.8rem;
$input-btn-padding-x-lg: 1.5rem;
// $input-btn-font-size-lg: 1rem;
// $input-btn-line-height-lg: $line-height-lg;

// $input-btn-border-width: $border-width;

// Buttons

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
// $btn-font-size: $input-btn-font-size;
// $btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
// $btn-font-size-sm: $input-btn-font-size-sm;
// $btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
// $btn-font-size-lg: $input-btn-font-size-lg;
// $btn-line-height-lg: $input-btn-line-height-lg;

// $btn-border-width: $input-btn-border-width;

// $btn-font-weight: $font-weight-bold;
$btn-box-shadow: none;
$btn-focus-width: $input-btn-focus-width;
// $btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: none;

// $btn-link-color: var(--#{$prefix}link-color);
// $btn-link-hover-color: var(--#{$prefix}link-hover-color);
// $btn-link-disabled-color: var(--#{$prefix}gray-400);

// Colors ---------------

// scss-docs-start theme-bg-subtle-variables
// $primary-bg-subtle: tint-color($primary, 80%);
$primary-bg-subtle: #f4fcfa;
$secondary-bg-subtle: tint-color($secondary, 80%);
$success-bg-subtle: tint-color($success, 80%);
$info-bg-subtle: tint-color($info, 80%);
$warning-bg-subtle: tint-color($warning, 80%);
$danger-bg-subtle: tint-color($danger, 80%);
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: tint-color($dark, 80%);
// scss-docs-end theme-bg-subtle-variables

$white: #fff;
$black: #222;

$theme-bg: linear-gradient(#3763eb 0%, #6f58e8 100%);
$theme-color: #3763eb;

$primary-color: $primary;
$secondary-color: $secondary;

$primary-color2: $success;

$border-color: #f4eefb;

// Colors
$body-color: #6a7c92;
$text-color: #222222;
// Heading Color
$heading-color: #051441;

// Responsive Variables
$desktop: "only screen and (min-width: 1400px)";
$laptop: "only screen and (min-width: 1200px) and (max-width: 1399px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 480px) and (max-width: 767px)";

// $small-breakpoint: em-calc(640) !default;
// $medium-breakpoint: em-calc(1024) !default;
// $large-breakpoint: em-calc(1440) !default;
// $xlarge-breakpoint: em-calc(1920) !default;

// // Here we define the lower and upper bounds for each media size
// $small-range: (0, $small-breakpoint) !default; /* 0, 640px */
// $medium-range: (
//   $small-breakpoint + em-calc(1),
//   $medium-breakpoint
// ) !default; /* 641px, 1024px */
// $large-range: (
//   $medium-breakpoint + em-calc(1),
//   $large-breakpoint
// ) !default; /* 1025px, 1440px */
// $xlarge-range: (
//   $large-breakpoint + em-calc(1),
//   $xlarge-breakpoint
// ) !default; /* 1441px, 1920px */
// $xxlarge-range: (
//   $xlarge-breakpoint + em-calc(1),
//   em-calc(99999999)
// ) !default; /* 1921px, ... */

// // Media Queries
// $screen: "only screen" !default;

// $landscape: "#{$screen} and (orientation: landscape)" !default;
// $portrait: "#{$screen} and (orientation: portrait)" !default;

// $small-up: $screen !default;
// $small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

// $medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
// $medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

// $large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
// $large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

// $xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
// $xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

// $xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
// $xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

// Create additional spacer
$custom-spacer: 1rem !default;
$custom-spacers: () !default;
$custom-spacers: map-merge(
  (
    6: (
      $custom-spacer * 4,
    ),
    //64px
    7:
      (
        $custom-spacer * 5,
      ),
    //80px
    8:
      (
        $custom-spacer * 6.25,
      ),
    //100px
    9:
      (
        $custom-spacer * 7.5,
      ),
    //120px
    10:
      (
        $custom-spacer * 9.375,
      )
      //150px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  ),
  $custom-spacers
);

// Update Bootstrap's default z-index map
$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  10: 10,
) !default;

// $input-padding-x: 1rem !default;
// $input-padding-x-sm: 0.875rem !default;
// $input-padding-x-lg: 1.125rem !default;

// $input-color: var(--#{$prefix}body-color) !default;
// $input-bg: transparent !default;
// $input-disabled-bg: var(--#{$prefix}gray-100) !default;
// $input-border-color: var(--#{$prefix}gray-400) !default;
// $input-disabled-border-color: var(--#{$prefix}gray-300) !default;

// $input-border-width: var(--#{$prefix}border-width) !default;
// $input-border-radius: var(--#{$prefix}border-radius) !default;
// $input-border-radius-sm: var(--#{$prefix}border-radius-sm) !default;
// $input-border-radius-lg: var(--#{$prefix}border-radius-lg) !default;

// // Input group
// $input-group-padding-y: 0.5rem !default;
// $input-group-padding-x: 0.5rem !default;
// $input-group-border-color: $input-border-color !default;
// $input-group-border-radius: var(--#{$prefix}border-radius-lg) !default;
// $input-group-border-radius-lg: var(--#{$prefix}border-radius-xl) !default;
// $input-group-border-radius-sm: var(--#{$prefix}border-radius) !default;
// // $input-group-addon-font-weight: $font-weight-medium !default;
// $input-group-addon-padding-x: $input-padding-x * 0.75 !default;
// $input-group-addon-bg: $input-bg !default;
// $input-group-addon-border-color: transparent !default;

// $swiper-pagination-bottom: 1rem;
:root {
  --swiper-pagination-bottom: 0px;
  --swiper-pagination-top: auto;
}
