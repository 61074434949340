$primary: #325d88;

// For Opacity
@mixin opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue +
    ")";
  filter: alpha(opacity=$IEValue);
}

.overly {
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $primary;
    @include opacity(0.6);
    z-index: -1;
  }
}

.feature-area2-style1 {
  background-color: #325d88;
  @extend .overly;
  padding: 170px 0 100px;

  &::before {
    background: url("../../../../../assets/images/new/banner/bg-shape.webp")
      no-repeat center bottom / cover;
    opacity: 1;
    height: 88px;
    top: auto;
    bottom: 0;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: url("../../../../../assets/images/new/banner/bg-shape.webp")
      no-repeat center top / cover;
    width: 100%;
    height: 88px;
    transform: rotate(-180deg);
  }

  .feature-item {
    span.icon.shadow {
      width: 80px;
      height: 80px;
      line-height: 80px;
      flex: 0 0 80px;
      border-radius: 50%;

      img {
        width: 40px;
      }

      // @media #{$small_device} {
      //   width: 60px;
      //   height: 60px;
      //   line-height: 60px;
      //   flex: 0 0 60px;

      //   img {
      //     width: 25px;
      //   }
      // }
    }

    h3 {
      font-size: 28px;
      margin-bottom: 5px;

      // @media #{$large_device} {
      //   font-size: 22px;
      // }

      // @media #{$tab_device} {
      //   font-size: 20px;
      // }

      // @media #{$small_device} {
      //   font-size: 22px;
      // }
    }
  }
}
