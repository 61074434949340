$alter-primary-dark: red;
$alter-advanced-indigo: red;

.psy-profile-competences {
  &__header {
    // @include headline3-bold;

    margin-bottom: 16px;
  }

  &__list-item {
    color: $alter-primary-dark;
    break-inside: avoid-column;
  }

  &__list-ul {
    column-count: 2;
    column-gap: 45px;
  }

  &__list-item-alter-plus {
    color: $alter-advanced-indigo;
    break-inside: avoid-column;
  }
}
