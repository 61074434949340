.testimonial2-section {
  .accordion .accordion-item {
    border: none;
  }

  i,
  svg {
    font-size: 62px;
  }
}
