/*===========================
    13. Footer CSS 
===========================*/

@import "../variables";

$social-radius: 45px;

.social-links {
  @media #{$xs} {
    margin-bottom: 15px;
  }

  ul {
    justify-content: flex-start;

    @media #{$xs} {
      justify-content: center;
    }

    li {
      display: inline-block;

      a {
        display: block;
        margin-right: 15px;

        width: $social-radius;
        height: $social-radius;
        border-radius: 50%;

        font-size: 18px;
        line-height: 45px;
        text-align: center;

        color: $body-color;
        background: rgba($body-color, 0.1);

        &:hover {
          color: $white;
          background: $primary;
        }

        @media #{$xs} {
          padding: 0 7px;
          margin-right: 10px;
        }
      }
    }
  }
}
