//----------------------------------------------------------//
//	SUBSCRIBE
//----------------------------------------------------------//

@import "../variables";
@import "../utilities/mixin";

$section-border-radius: 30px;

.custom-card {
  position: relative;
  overflow: hidden;
  padding: 30px;
  height: 100%;

  //   border-radius: 25px;
  border-radius: $section-border-radius;

  transition: all 0.3s ease;

  &:hover {
    background-color: $secondary;
    transform: translateY(-3px);
  }

  & > a {
    width: 100%;
  }

  &__image {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-top: 35px;
  }

  // .custom-block .rounded-pill {
  //   border-radius: 5px !important;
  //   display: flex;
  //   justify-content: center;
  //   text-align: center;
  //   width: 30px;
  //   height: 30px;
  //   line-height: 20px;
  // }

  // .custom-block-overlay {
  //   height: 100%;
  //   min-height: 350px;
  //   padding: 0;
  // }

  // .custom-block-overlay > a {
  //   height: 100%;
  // }

  // .custom-block-overlay .custom-block-image {
  //   border-radius: var(--border-radius-medium);
  //   display: block;
  //   height: 100%;
  //   margin-top: 0;
  // }

  // .custom-block-overlay-text {
  //   position: absolute;
  //   z-index: 2;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   padding: 30px;
  // }

  // .section-overlay + .container {
  //   position: relative;
  // }
}
