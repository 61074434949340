/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/

.features {
  h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .icon-list {
    i {
      margin-right: 10px;
      font-size: 24px;
      line-height: 1;
    }

    span {
      font-size: 16px;
      color: color-mix(in srgb, var(--default-color), transparent 20%);
    }
  }

  .phone-wrap {
    position: absolute;
    right: 0;

    img {
      width: 340px;
    }
  }

  .details {
    margin-top: 80px;
    padding: 120px 0;
    background-color: color-mix(in srgb, #2b180d, transparent 97%);

    h4 {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: 15px;
    }
  }
}

@media (max-width: 768px) {
  .features .phone-wrap {
    position: relative;
  }
}

@media (max-width: 992px) {
  .features .phone-wrap img {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .features .phone-wrap img {
    width: 100%;
  }
}
