.testimonial-section {
  &:not(.page-home-2) {
    .content-inner {
      padding: 40px;
      border-radius: 20px;
      background-color: #fedac2;
    }

    .wrap-testimonials {
      display: flex;
      padding-bottom: 40px;
      border-bottom: 1px solid #e4e4e4;
      margin-bottom: 40px;
    }

    .image-wrap {
      //   max-width: 520px;
      border-radius: 16px;
      height: 100%;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .wrap-counter {
    display: flex;
    justify-content: space-between;

    .counter-item {
      &:not(.has-icon) {
        &:not(:last-child) {
          border-right: 1px solid #e4e4e4;
          padding-right: 79px;
        }
        &:not(:first-child) {
          padding-left: 79px;
        }
      }
      &.has-icon {
        span {
          font-size: 32px;
          line-height: 40px;
        }
        p {
          font-size: 18px;
          line-height: 30px;
        }
        &:not(:last-child) {
          padding-right: 40px;
          border-right: 1px solid #e4e4e4;
        }
        &:not(:first-child) {
          padding-left: 10px;
        }
      }
    }

    @media (max-width: 1170px) {
      flex-wrap: wrap;
      gap: 30px 0;

      .counter-item {
        text-align: center;

        width: 49% !important;
        padding: 0 !important;
        &:not(:nth-child(odd)) {
          border-right: unset !important;
        }
        .counter-number {
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }
}
