.pictures {
  display: grid;
  gap: 8px;
  grid-auto-columns: 104px;
  grid-auto-rows: 104px;
  grid-template-areas: ". p0 p1 p2 ." "p3 p4 p4 p5 p6" "p7 p4 p4 p8 p9" ". p10 p11 p12 .";
  position: relative;
}

.pictures_collapsed {
  grid-template-areas: "p0 p1 p2" "p3 p3 p4" "p3 p3 p5" "p6 p7 p8";
}

.pictures_collapsed .pictures__dec_top {
  right: -86px;
}

.pictures_collapsed .pictures__dec_bottom {
  left: -80px;
}

@media (max-width: 767px) {
  .pictures_collapsed .pictures__dec_top {
    right: -18px;
    row-gap: 24px;
    top: -80px;
  }

  .pictures_collapsed .pictures__dec_top .pictures__dec-text {
    left: unset;
    right: 14px;
  }

  .pictures_collapsed .pictures__dec_bottom {
    bottom: -66px;
    left: -60px;
    row-gap: 0;
  }

  .pictures_collapsed .pictures__dec_bottom .pictures__dec-text {
    left: 50px;
    right: unset;
  }

  .pictures_collapsed .pictures__dec_bottom .pictures__dec-curve {
    transform: rotate(-34deg);
  }
}

.pictures_collapsed .pictures__item_p6 {
  border-radius: 4px;
  justify-self: end;
}

.pictures_collapsed .pictures__item_p7 {
  border-radius: 8px;
}

.pictures_collapsed .pictures__item_p8 {
  border-bottom-right-radius: 24px;
}

.pictures__item {
  border-radius: 8px;
  height: 100%;
  position: relative;
  width: 100%;
}

.pictures__item_size-small {
  border-radius: 4px;
  height: 64px;
  width: 64px;
}

.pictures__item_size-large {
  border-radius: 12px;
}

.pictures__item_p0 {
  border-top-left-radius: 24px;
}

.pictures__item_p2 {
  align-self: end;
}

.pictures__item_p3 {
  align-self: end;
  justify-self: end;
}

.pictures__item_p6 {
  border-top-right-radius: 24px;
}

.pictures__item_p7 {
  border-bottom-left-radius: 24px;
}

.pictures__item_p10 {
  justify-self: end;
}

.pictures__item_p12 {
  border-bottom-right-radius: 24px;
}

.pictures__item-data,
.pictures__item-data:after {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pictures__item-data:after {
  background: linear-gradient(9deg, #14141466 14.29%, #14141400 51.39%);
  content: "";
  z-index: 0;
}

.pictures__item-inner {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pictures__item-tag {
  background: #1414144d;
  border-radius: 12px;
  padding-bottom: 2px;
  padding-top: 2px;
  top: 8px;
}

.pictures__item-info,
.pictures__item-tag {
  color: #fff;
  left: 8px;
  position: absolute;
  z-index: 1;
}

.pictures__item-info {
  bottom: 8px;
}

.pictures__item-footer,
.pictures__item-name {
  color: #fff;
}

.pictures__dec {
  pointer-events: none;
  position: absolute;
}

.pictures__dec_top {
  right: 24px;
  row-gap: 14px;
  top: -24px;
}

.pictures__dec_top .pictures__dec-text {
  left: 36px;
  transform: rotate(10deg);
}

.pictures__dec_bottom {
  bottom: -56px;
  left: 24px;
  row-gap: 6px;
}

.pictures__dec_bottom .pictures__dec-text {
  right: 4px;
  transform: rotate(-5deg);
}

.pictures__dec-text {
  max-width: 166px;
  position: relative;
  text-align: center;
}

.pictures__badge {
  display: none;
}

@media (max-width: 767px) {
  .pictures__badge {
    display: flex;
    height: 55px;
    left: 0;
    position: absolute;
    top: -46px;
    width: 176px;
  }
}

.pictures .matching-fade-enter-active,
.pictures .matching-fade-leave-active {
  transition: all 0.5s ease;
}

.pictures .matching-fade-enter-from,
.pictures .matching-fade-leave-to {
  opacity: 0;
}

.matching {
  background: linear-gradient(180deg, #f6f2ea 35%, #fff 0, #fff 65%, #2d2952 0);
  padding: 100px 0 136px;
  position: relative;
  z-index: 1;
}

.matching:after,
.matching:before {
  background: #fff;
  border-radius: 50%;
  content: "";
  height: 613px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 196vw;
  z-index: -1;
}

.matching:before {
  top: 0;
}

.matching:after {
  bottom: 0;
}

@media screen and (max-width: 1024px) {
  .matching {
    padding: 56px 0 48px;
  }

  .matching:after,
  .matching:before {
    height: 800px;
    width: 1224px;
  }
}

@media (max-width: 767px) {
  .matching {
    padding: 40px 0 48px;
  }

  .matching:after,
  .matching:before {
    height: 612px;
    width: 1000px;
  }
}

.matching__inner {
  -moz-column-gap: 80px;
  column-gap: 80px;
  max-width: 1182px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .matching__inner {
    flex-direction: column;
  }
}

.matching__left {
  margin-left: 23px;
}

@media screen and (max-width: 1280px) {
  .matching__left {
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .matching__right {
    margin-top: 72px;
  }
}

@media (max-width: 767px) {
  .matching__right {
    margin-top: 124px;
  }
}

.matching__title {
  max-width: 400px;
}

.matching__title span {
  position: relative;
  white-space: nowrap;
  z-index: 0;
}

.matching__title span:before {
  background-color: #ebf7ec;
  border-radius: 8px;
  content: "";
  height: 72%;
  left: -4px;
  position: absolute;
  top: 10px;
  width: calc(100% + 8px);
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .matching__title {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .matching__title {
    font-size: 28px;
    letter-spacing: -1.12px;
  }
}

@media screen and (max-width: 1024px) {
  .matching__subtitle {
    display: none;
  }

  .matching__subtitle_mobile {
    border-top: 1px solid #2d2952;
    display: flex;
    width: 100%;
  }
}

.matching__chips {
  max-width: 324px;
}

@media screen and (max-width: 1024px) {
  .matching__chips {
    display: none;
  }

  .matching__chips_mobile {
    display: flex;
    max-width: 440px;
  }
}

@media (max-width: 767px) {
  .matching__chips_mobile {
    max-width: 328px;
  }
}

.matching__button {
  margin-top: 44px;
}

@media screen and (max-width: 1024px) {
  .matching__button {
    display: none;
  }

  .matching__button_mobile {
    display: flex;
    margin-top: 84px;
  }
}

@media (max-width: 767px) {
  .matching__button_mobile {
    margin-top: 104px;
    width: 100%;
  }
}

.matching__chip {
  border-color: #e5dcca !important;
  border-radius: 20px;
  cursor: pointer;
}

.matching__chip_last .mui-chip__text {
  color: #f69546;
}

@media screen and (max-width: 1024px) {
  .matching__footer {
    width: 100%;
  }
}
