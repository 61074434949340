.launcher-form-wrapper {
  //margin-left: auto;
  //margin-right: auto;
  //margin-top: -27px;
  padding: 5rem 5rem;

  //width: 54px;
  //height: 54px;

  background: white;

  border: 1px solid #f4eefb;
  border-radius: 2rem;

  color: white;
  //border-color: transparent;
  //background: $theme-bg;
}
