.tab-content {
  //   background-color: var(--white-color);
  border-radius: var(--border-radius-medium);
}

.nav-tabs .nav-link {
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  color: var(--p-color);
  font-family: var(--title-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--font-weight-medium);
  padding: 15px 25px;
  transition: all 0.3s;
}

// .nav-tabs .nav-link:first-child {
//   margin-right: 20px;
// }

// .nav-tabs .nav-item.show .nav-link,
// .nav-tabs .nav-link.active,
// .nav-tabs .nav-link:focus,
// .nav-tabs .nav-link:hover {
//   border-bottom-color: var(--primary-color);
//   color: var(--primary-color);
// }
