.quiz-wrapper {
  // max-width: 500px;
  // min-width: 250px;
  // background: #ffffff;
  // border-radius: 4px;
  // margin-top: 100px;
  // padding: 30px 60px;

  .active-question-no {
    // font-size: 32px;
    font-weight: 500;
    color: $primary;
  }

  .total-question {
    // font-size: 16px;
    font-weight: 500;
    // color: #e0dee3;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  ul {
    // margin-top: 20px;
    // margin-left: -40px;

    li {
      text-decoration: none;
      list-style: none;
      // color: #2d264b;
      // font-size: 16px;
      // background: #ffffff;
      border: 1px solid #eaeaea;
      border-radius: 16px;
      // padding: 11px;
      // margin-top: 15px;
      cursor: pointer;
    }

    .selected-answer {
      background: $gray-100;
      border: 1px solid $primary;
    }
  }

  button {
    &:disabled {
      background: #e7e8e9;
      color: #9fa3a9;
      cursor: not-allowed;
    }
  }
}

.result {
  h3 {
    font-size: 24px;
    letter-spacing: 1.4px;
    text-align: center;
  }

  p {
    // font-size: 16px;
    font-weight: 500;

    span {
      color: $primary;
      font-size: 22px;
    }
  }
}
