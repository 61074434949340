/*--------------------------------------------------------------
# Alt Features Section
--------------------------------------------------------------*/
.alt-features-section {
  .icon-box {
    display: flex;

    h4 {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 10px 0;
    }

    i,
    svg {
      margin-right: 15px;
      color: var(--accent-color);

      font-size: 44px;
      line-height: 44px;
    }

    p {
      margin-bottom: 0;
      color: color-mix(in srgb, var(--default-color), transparent 30%);
      font-size: 0.8rem;
    }
  }
}
