/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.psy-about-section {
  .content {
    background-color: color-mix(in srgb, #4154f1, transparent 95%);
    padding: 40px;

    h3 {
      font-size: 14px;
      font-weight: 700;
      color: var(--accent-color);
      text-transform: uppercase;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
    }

    p {
      margin: 15px 0 30px 0;
      line-height: 24px;
    }

    .btn-read-more {
      color: #4154f1;
      background: #4154f1;
      line-height: 0;
      padding: 15px 40px;
      border-radius: 4px;
      transition: 0.5s;
      box-shadow: 0px 5px 25px color-mix(in srgb, #4154f1, transparent 90%);

      span {
        font-family: var(--default-font);
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
      }

      i {
        margin-left: 5px;
        font-size: 18px;
        transition: 0.3s;
      }

      &:hover i {
        transform: translateX(5px);
      }
    }
  }
}
