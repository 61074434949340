.page-not-found {
  height: 100vh;
}
.page-not-foud-content-wrapper {
  h1 {
    color: $theme-color;
    font-size: 80px;
    font-weight: 900;
    text-transform: uppercase;
  }

  h2 {
    //font-size: 40px;
    color: $body-color;
    //text-transform: uppercase;
  }

  h3 {
    //font-size: 40px;
    color: $body-color;
    //text-transform: uppercase;
  }

  a {
  }
}
