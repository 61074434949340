.section-what-we-do-style2 {
  @media (min-width: 992px) {
    .position-lg-absolute {
      position: absolute !important;
    }
    .position-lg-sticky {
      position: sticky;
    }
  }

  .image-wrapper {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 0;

    &.bg-overlay:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      // background: rgba(30, 34, 40, 0.5);
      opacity: 0.85;
      background-image: linear-gradient(90deg, #02010100 0%, #030303 100%);
    }
  }

  .demo-four-service-card {
    //   display: flex;
    //   justify-content: space-between;
    align-items: center;
    border-radius: 25px;

    &.bg-color1 {
      background: #eff0fe;
    }
    &.bg-color2 {
      background: #faf6e7;
    }
    &.bg-color3 {
      background: #f5f6f9;
    }
    &.bg-color4 {
      background: #ede3eb;
    }
    &.bg-color5 {
      background: #ebf3fa;
    }

    img {
      border-radius: 25px;
      min-height: 427px;
    }

    .image {
      width: 306px;
      height: 354px;
      flex-shrink: 0;
      img {
        border-radius: 25px;
      }
    }
    .section-content {
      padding: 30px;

      h3 {
        // color: var(--headingColor);
        // font-size: 24px;
        // margin-bottom: 25px;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 25px;
        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 10px;
          color: var(--headingColor);
          font-weight: 600;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            position: absolute;
            left: 0;
            line-height: 1;
            top: 8px;
            font-size: 20px;
            color: var(--primaryColor);
          }
          br {
            display: none;
          }
        }
      }
      .icon {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 58px;
        font-size: 18px;
        display: inline-block;
        color: var(--primaryColor);
        border-radius: 50%;
        background-color: transparent;
        border: solid 1px var(--primaryColor);
      }
    }
    &:hover {
      .content {
        .icon {
          color: var(--#{$prefix}white);
          background-color: var(--#{$prefix}primary);
        }
      }
    }
  }
}

//----------------------------------------------------------//
//	BANNER
//----------------------------------------------------------//

@import "../variables";
@import "../utilities/mixin";

$dark-divider-color: #ffffff1a;
$divider-color: $gray-300;

.what-we-do-section {
  background: linear-gradient(180deg, $secondary 65%, $white 35%);
  padding: 100px 0 50px;
}

.intro-video-box {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  padding: 170px 100px 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(38, 38, 38, 0) 65.21%,
      rgba(38, 38, 38, 0.8) 100%
    );
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.intro-bg-video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video-play-button {
  position: relative;
  z-index: 1;

  a {
    position: relative;
    background-color: $secondary;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: none;

    &:before {
      content: "";
      position: absolute;
      top: -30%;
      left: -30%;
      width: 160%;
      height: 160%;
      border: 50px solid $divider-color;
      border-radius: 50%;
      transform: scale(0.6);
      z-index: -1;
      animation: border-zooming 1.2s infinite linear;
    }

    &:after {
      content: "";
      position: absolute;
      top: -30%;
      left: -30%;
      width: 160%;
      height: 160%;
      border: 50px solid $divider-color;
      border-radius: 50%;
      transform: scale(0.6);
      z-index: -1;
      animation: border-zooming 1.2s infinite linear;
      animation-delay: 0.3s;
    }

    i {
      font-size: 30px;
      color: $white;
    }
  }
}

@keyframes border-zooming {
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.intro-video-counter {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px 120px;
  border-top: 1px solid $dark-divider-color;
  padding-top: 50px;
  margin-top: 170px;
  z-index: 1;
}

.video-counter-item {
  h2 {
    font-size: 50px;
    color: $white;
    margin-bottom: 10px;
  }

  p {
    color: $white;
    text-transform: capitalize;
    opacity: 80%;
    margin: 0;
  }
}
