// $color_1: var(--default-color);
// $color_2: var(--contrast-color);
// $font-family_1: var(--heading-font);

/* Call-to-action Section - Home Page
------------------------------*/

.section-call-to-action-style3 {
  //   --default-color: #ffffff;
  //   --background-color: #000000;
  //   --background-color-rgb: 0, 0, 0;
  //   padding: 80px 0;
  position: relative;
  //   clip-path: inset(0);

  //   img {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     z-index: 1;
  //   }

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  //   h3 {
  //     font-size: 28px;
  //     font-weight: 700;
  //     color: $color_1;
  //   }
  //   p {
  //     color: $color_1;
  //   }
  //   .cta-btn {
  //     // @extend %theme-btn;
  //   }
}
