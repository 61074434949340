/*===========================
    02. Header CSS 
===========================*/

.navbar-area_ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  @include transition(0.3s);
  padding: 0;
}

.sticky_ {
  position: fixed;
  z-index: 100;
  background: $theme-color;
  @include box-shadow(0px 20px 50px 0px rgba(0, 0, 0, 0.05));
  @include transition(0.3s);
  background: $white;
  padding: 0px 0;

  .navbar {
    padding: 10px 0;
  }
}

.navbar_ {
  padding: 20px 0;
  position: relative;
  @include transition(0.3s);
}

.navbar-brand_ {
  padding: 0;
  img {
    max-width: 150px;
  }
  span {
    margin-left: 0.5rem;
  }
}

.navbar-toggler_ {
  padding: 0px;

  & .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: $black;
    display: block;
    margin: 5px 0;
    position: relative;
    @include transition(0.3s);
  }

  &.active {
    & .toggler-icon {
      &:nth-of-type(1) {
        @include transform(rotate(45deg));
        top: 7px;
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        @include transform(rotate(135deg));
        top: -7px;
      }
    }
  }
}

.navbar-collapse_ {
  @media #{$md} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 9;
    @include box-shadow(0px 15px 20px 0px rgba(0, 0, 0, 0.1));
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
  }

  @media #{$xs} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 9;
    @include box-shadow(0px 15px 20px 0px rgba($heading-color, 0.1));
    padding: 5px 12px;
    max-height: 350px;
    overflow-y: scroll;
  }
}

.navbar-nav_ {
  & .nav-item {
    position: relative;
    z-index: 1;

    &:hover {
      a {
        color: $theme-color;
      }
    }

    & a {
      font-size: 16px;
      color: $heading-color;
      @include transition(0.3s);
      position: relative;
      padding: 20px;
      display: inline-flex;
      align-items: center;

      @media #{$lg} {
        padding: 20px 15px;
      }

      @media #{$md} {
        color: $heading-color;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
      }

      @media #{$xs} {
        color: $heading-color;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
      }

      &.active {
        color: $theme-color;
      }

      &.dd-menu {
        padding-right: 34px;

        @media #{$lg} {
          padding-right: 30px;
        }

        &::after {
          content: "\ea58";
          font: normal normal normal 1em/1 "LineIcons";
          position: absolute;
          right: 18px;
          font-size: 12px;
          margin-left: 5px;
          margin-top: 0px;
          @include transition(0.3s);

          @media #{$lg} {
            right: 13px;
          }

          @media #{$md} {
            right: 0;
          }

          @media #{$xs} {
            right: 0;
          }
        }
      }

      &.show {
        &::after {
          @media #{$md} {
            @include transform(rotate(180deg));
          }

          @media #{$xs} {
            @include transform(rotate(180deg));
          }
        }
      }
    }

    &:hover {
      & > .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible;

        @media #{$md} {
          visibility: hidden;
          opacity: 0;
        }

        @media #{$xs} {
          visibility: hidden;
          opacity: 0;
        }

        .sub-menu {
          left: 100%;
          top: 0;
        }
      }
    }

    & .sub-menu {
      min-width: 200px;
      background-color: $white;
      @include box-shadow(0px 0px 20px 0px rgba(0, 0, 0, 0.1));
      position: absolute;
      top: 110%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      @include transition(0.3s);
      padding: 0;

      &.left-menu {
        left: -100%;
      }

      &::after {
        content: "";
        position: absolute;
        background: $white;
        width: 20px;
        height: 20px;
        top: -10px;
        left: 20%;
        @include transform(rotate(45deg));
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        border-left: 1px solid rgba(0, 0, 0, 0.05);
      }
      @media #{$md} {
        position: static;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        transform: scaleY(0);
        transform-origin: top;
        @include box-shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.05));

        &.show {
          max-height: 1000px;
          height: auto;
          opacity: 1;
          visibility: visible;
          transform: scaleY(1);
        }

        &::after {
          display: none;
        }
      }

      @media #{$xs} {
        position: static;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        transform: scaleY(0);
        transform-origin: top;
        @include box-shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.05));

        &.show {
          max-height: 1000px;
          height: auto;
          opacity: 1;
          visibility: visible;
          transform: scaleY(1);
        }

        &::after {
          display: none;
        }
      }

      & > li {
        display: block;
        margin-left: 0;

        &:last-child {
          border: none;
        }
        &.active,
        &:hover {
          & > a {
            color: $theme-color;
          }
        }

        &:first-child {
          a {
            border-top: none;
          }
        }

        & > a {
          font-weight: 400;
          display: block;
          padding: 10px 15px;
          font-size: 14px;
          color: $black;
          border-top: 1px solid rgba(0, 0, 0, 0.03);

          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}
