//----------------------------------------------------------//
//	PAGINATION
//----------------------------------------------------------//

@import "../variables";
@import "../utilities/mixin";

.pagination {
  // box-shadow: $box-shadow;

  .page-item {
    margin: 0 5px;

    .page-link {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;
      border-radius: 50%;

      color: $pagination-color;
      background: $pagination-bg;

      // border: 1px solid $pagination-border-color;

      @include media-breakpoint-up(lg) {
        width: 50px;
        height: 50px;

        box-shadow: $box-shadow;
      }
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &.active,
    &:hover:not([disabled]) {
      .page-link {
        color: $pagination-active-color;
        background: $pagination-active-bg;

        @include box-shadow(0px 0px 3px rgba($primary, 0.45));
      }
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.pagination-container-old {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.block-27 ul {
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 400;

    a,
    span {
      color: gray;
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      border: 1px solid #e6e6e6;
    }

    &.active {
      a,
      span {
        background: #4ba1fa;
        color: #fff;
        border: 1px solid transparent;
      }
    }
  }
}

// // For better responsive
// .pagination {
//   .page-item {
//     margin-bottom: 5px;
//     float: left;
//   }
// }

//
// Additional style for theme
//
// //Pagination primary soft
// .pagination-primary-soft{
//   border: none;
//   padding: 4px;
//   border-radius: $border-radius;
//   .page-item{
//     margin: 3px;
//     &.page-item.active .page-link{
//       color: $white;
//       background-color: var(--#{$prefix}primary);
//     }
//   }
//   .page-link{
//     border: transparent;
//     border-radius: $border-radius !important;
//     color: var(--#{$prefix}gray-700);
//     background-color: var(--#{$prefix}light);
//     &:hover {
//       z-index: 2;
//       color: $white;
//       background-color: var(--#{$prefix}primary);
//       border-color: var(--#{$prefix}primary);
//     }
//   }
// }
