/* Grid
   ========================================================================== */

.panel-box {
  //background-image: url(/assets/images/dev/tiny_grid.webp);
  border: 1px solid #999;
  border-radius: 2rem;
  padding: 1rem;
  position: relative;
  > a {
    &:link {
      text-decoration: none;
      color: #000;
    }
    &:visited {
      text-decoration: none;
      color: #000;
    }
  }
  ul {
    margin: 1rem 0;
    padding: 0 0 0 1.2em;
  }
  h3 {
    margin: 0;
  }

  .panel-item {
    height: 100%;

    display: flex;

    justify-content: center;
    align-content: space-between;

    flex-wrap: wrap;
    flex-direction: row;

    padding: 0.5rem;

    .panel-title {
      //display: block;
      // width: 250px;
      // float: left;
    }

    .panel-image {
      //display: block;

      height: 80%;
      max-width: 100%;
      max-height: 100%;

      object-fit: contain;
      overflow: hidden;

      //height: auto;
      // display: inline; /* This should be block, according to the test. But why? */

      // display: inline;
      // width: 100%;
      // height: 100%;

      // min-width: 100%;
      // min-height: 100%;
      // flex-shrink: 0;
    }
  }
}

.panel-primary {
  // background-image: url(assets/images/dev/inflicted.webp);
  color: #fff;
  a {
    &:link {
      color: #fff;
    }
    &:visited {
      color: #fff;
    }
  }
}

.home-layout {
  margin-bottom: 1em;
  .panel-box {
    margin-bottom: 1em;
  }
}

@media screen and (min-width: 640px) {
  .home-layout {
    display: grid;
    grid-gap: 1em;
    //grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    //grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    .panel-box {
      margin-bottom: 0;
    }

    .panel-a {
      grid-column: auto / span 1;
    }
    .panel-b {
      grid-column: auto / span 2;
    }
    .panel-c {
      grid-column: auto / span 3;
    }
    .panel-d {
      grid-column: 1 / -1;
    }
    .panel-e {
      grid-column: 1 / 4;
    }
    .panel-f {
      .panel-image {
        height: 70%;
      }
    }
    .row-2x {
      grid-row-end: span 2;
    }
    .row-3x {
      grid-row-end: span 3;
    }
  }
}
