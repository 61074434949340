.auth-social {
  font-size: 15px;
  color: #919aa3;
  display: flex;
  margin-bottom: 23px;

  &::before,
  &::after {
    content: "";
    background-image: linear-gradient(#f4f4f4, #f4f4f4);
    flex-grow: 1;
    background-size: calc(100% - 20px) 1px;
    background-repeat: no-repeat;
  }

  &::before {
    background-position: center left;
  }

  &::after {
    background-position: center right;
  }
}

.page-auth,
.page-logout,
.page-forgot-password {
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* background: url(../images/bg-registration-form-4.jpg) no-repeat; */
  /*background-size: cover; */

  .auth-wrapper {
    margin: auto;
  }
}
