.intro-section {
  background-image: url(assets/images/bg/login-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 75px 95px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #ffffff;

  @media (max-width: 991px) {
    padding-left: 50px;
    padding-rigth: 50px;
  }

  @media (max-width: 767px) {
    padding: 28px;
  }

  @media (max-width: 575px) {
    min-height: auto;
  }
}

.brand-wrapper {
  .logo {
    height: 35px;
  }

  @media (max-width: 767px) {
    margin-bottom: 35px;
  }
}

.intro-content-wrapper {
  width: 371px;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  .intro-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 18px;
  }

  .intro-text {
    font-size: 14px;
    line-height: 1.29;
    color: #ffffff;
  }

  .btn-read-more {
    background-color: #fff;
    padding: 13px 30px;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
    color: #000;

    &:hover {
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
    }
  }
}

.intro-section-footer {
  @media (max-width: 767px) {
    margin-top: 35px;
  }

  p {
    font-size: 14px;
    color: #f6f6f6;
    margin-bottom: 0;
  }
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 35px;
  }

  @media (max-width: 575px) {
    padding: 26px;
  }
}

.login-wrapper {
  width: 410px;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 575px) {
    width: 100%;
  }

  .form-control {
    min-height: 55px;
    border: 0;
    border-radius: 4px;
    font-size: 15px;
    background-color: #f4f4f4;
    padding: 19px 25px 18px;
    margin-bottom: 18px;

    &::placeholder {
      color: #919aa3;
    }
  }
}

.login-title {
  font-size: 29px;
  color: #0d2366;
  margin-bottom: 18px;
}

.login-check-box {
  padding-left: 33px;
  margin-bottom: 23px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

  .custom-control-label {
    &::before {
      width: 20px;
      height: 20px;
      border: solid 1px #d5dae2;
      left: -33px;
      top: 0px;
      background-color: transparent;
    }

    &::after {
      left: -33px;
      width: 20px;
      height: 20px;
      top: 0;
    }
  }
}

.login-btn {
  padding: 17px 40px 16px 41px;
  border-radius: 4px;
  background-color: #f0004f;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;

  &:hover {
    border: 1px solid #f0004f;
    background-color: transparent;
    color: #f0004f;
  }
}

.forgot-password-link {
  font-size: 14px;
  color: #919aa3;
}

.social-login-title {
  font-size: 15px;
  color: #919aa3;
  display: flex;
  margin-bottom: 23px;

  &::before,
  &::after {
    content: "";
    background-image: linear-gradient(#f4f4f4, #f4f4f4);
    flex-grow: 1;
    background-size: calc(100% - 20px) 1px;
    background-repeat: no-repeat;
  }

  &::before {
    background-position: center left;
  }

  &::after {
    background-position: center right;
  }
}

.social-login-links {
  text-align: center;
  margin-bottom: 0;
}

.social-login-link {
  display: inline-block;
  border-radius: 4px;
  border: solid 1px #d5dae2;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #000000;
  margin-top: 14px;

  @media (max-width: 767px) {
    font-size: 10px;
    padding: 10px 12px;
  }

  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    object-fit: contain;
  }

  + .social-login-link {
    margin-left: 24px;

    @media (max-width: 767px) {
      margin-left: 6px;
    }
  }
}

.login-wrapper-signup-text {
  font-size: 18px;
  color: #000;
  padding-top: 50px;
  width: 410px;
  max-width: 100%;

  @media (max-width: 575px) {
    order: 1;
  }

  a {
    color: #240aad;
  }
}
