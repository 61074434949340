//----------------------------------------------------------//
//	SECTION: PROBLEMS
//----------------------------------------------------------//

$section-border-radius: 30px;

.section-problems-style2 {
  .service-item {
    position: relative;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    overflow: hidden;

    border-radius: $section-border-radius;

    .service-image {
      // a {
      //   position: relative;
      //   display: block;
      //   cursor: none;

      //   &::before {
      //     content: "";
      //     position: absolute;
      //     top: 0;
      //     bottom: 0;
      //     left: 0;
      //     bottom: 0;
      //     background: #262626;
      //     opacity: 50%;
      //     width: 100%;
      //     height: 100%;
      //     z-index: 1;
      //   }
      // }

      &__anime {
        position: relative;
        // overflow: hidden;
        margin: 0;
      }

      img {
        border-radius: $section-border-radius;
        aspect-ratio: 1 / 0.97;
        object-fit: cover;

        transition: all 0.4s ease-in-out;
      }
    }

    &:hover .service-image img {
      transform: scale(1.1);
    }

    .service-content {
      position: absolute;
      top: 30px;
      left: 30px;
      right: 30px;
      max-width: 210px;
      z-index: 2;

      &__title {
        margin: 0;
        color: $white;

        font-size: 20px;
      }
    }

    .service-control {
      position: absolute;
      bottom: 30px;
      left: 30px;
      right: 30px;
      z-index: 2;

      &__text {
        //display: block;
        margin: 0;
        color: $gray-200;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
      }

      @include media-breakpoint-up(md) {
        &__text {
          -webkit-line-clamp: 4;
          line-clamp: 4;
        }
      }
      @include media-breakpoint-up(lg) {
        &__text {
          display: block;
          // -webkit-line-clamp: 5;
          // line-clamp: 5;
        }
      }

      // .readmore-btn {
      //   display: block;
      //   color: $white;

      //   &:hover {
      //     color: $primary;
      //   }

      //   &::before {
      //     filter: brightness(0) invert(1);
      //   }
      // }
    }
  }
}
