//----------------------------------------------------------//
//	SUBSCRIBE
//----------------------------------------------------------//

@import "../variables";
@import "../utilities/mixin";

.section-subscribe-style3 {
  margin-bottom: -8rem !important;

  .container {
    z-index: 5;
    //  py-0;
    //   mb-n7
  }

  figure {
    // .mb-n3
    margin-bottom: -1rem !important;
  }
}

.subscribe-section {
  // text
  span,
  h2 {
    color: $white;
  }

  .subscribe-form {
    small {
      color: $white;
      a {
        color: $white;
      }
    }
  }
}

.subscribe-section,
.subscribe2-section {
  position: relative;

  .subscribe-form {
    position: relative;

    input {
      border: 2px solid transparent;
      border-radius: 50px;
      width: 100%;
      font-size: 16px;
      padding: 18px 30px;
      background: $white;
      @include transition(0.3s);

      &:focus {
        @include box-shadow(0 0 30px rgba($white, 0.15));
      }
    }

    button {
      // @extend %theme-round-btn;
    }

    small {
      display: block;
      margin-top: 10px;

      a {
        display: inline;
      }
    }
  }
}

$color_1: #5034fc;
$color_2: #061e5c;

$background-color_1: #061e5c;
$background-color_2: $white;

.subscribe2-section {
  padding-bottom: 0;
  padding-top: 60px;

  background-color: $background-color_1;

  .content {
    padding: 50px;
    background-color: $background-color_2;
    border-radius: 10px;

    h5 {
      color: $color_1;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 32px;
      font-weight: 800;
      color: $color_2;
      line-height: 42px;
    }
  }
}
