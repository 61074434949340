/**
 * File: topmenu.scss
 *
 * Option:
 * Author:
 * License:
*/

$header-menu-zindex: 1009;

.top-menu {
  &.navbar {
    .dropdown-menu {
      min-width: 220px;

      .nav-link-dropdown {
        padding: 0.8rem 20px;
      }
    }

    .nav-link {
      &:hover {
        color: $primary;
      }
    }

    // padding: 0px 0;

    // .navbar {
    //   padding: 10px 0;
    // }
  }

  &.navbar-sticky {
    &.style2 {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      // z-index: $header-menu-zindex;

      @include box-shadow(0px 20px 50px 0px rgba(0, 0, 0, 0.05));
    }

    &.style2 {
      --pw-bg-opacity: 0.9;
      background-color: rgba(
        var(--#{$prefix}white-rgb),
        var(--#{$prefix}bg-opacity)
      );
      border-bottom: $border-width solid $border-color;

      transform: translateY(0);
      @include transition(0.3s);

      // animation: fadeInDown 0.5s;
    }
  }

  &.navbar-transparent {
    position: absolute;
    left: 0;
    right: 0;

    background-color: transparent;
    box-shadow: none;

    z-index: $header-menu-zindex;
  }
}
