//----------------------------------------------------------//
//	HERO
//----------------------------------------------------------//

@import "../variables";
@import "../utilities/mixin";

$top-menu-height: 120px;

.hero-section {
  padding-top: $top-menu-height;

  .hero-content-wrapper {
    padding-top: 150px;
    padding-bottom: 290px;

    //font-family: Roboto;

    h1 {
      margin-bottom: 2rem;

      color: $heading-color;

      //font-weight: bold;
      //font-size: 50px;
      //line-height: 4rem;

      @include font-source(50px, bold, 4rem, $heading-color);

      @media #{$xs} {
        //font-size: 40px;
        //line-height: 3rem;
        @include font-source(40px, false, 3rem, false);
      }
    }

    h2 {
      margin-bottom: 2rem;

      color: $theme-color;

      //font-weight: 700;
      //font-size: 25px;
      //line-height: 2rem;
      @include font-source(30px, 700, 2rem, $theme-color);
    }

    p {
      margin-bottom: 3rem;

      //color: $body-color;
      font-size: 18px;
      line-height: 26px;

      opacity: 0.8;
    }

    @media #{$desktop} {
      padding-top: 130px;
      padding-bottom: 230px;
    }

    @media #{$laptop} {
      padding-top: 130px;
      padding-bottom: 230px;
    }

    @media #{$lg} {
      padding-top: 130px;
      padding-bottom: 200px;
    }

    @media #{$md} {
      padding: 90px 0;
    }

    @media #{$xs} {
      padding: 90px 0 60px;
    }
  }

  .hero-img {
    position: absolute;
    right: 0;
    top: 107px;
    width: 48%;

    text-align: right;

    @media #{$desktop} {
      top: 107px;
    }

    @media #{$laptop} {
      top: 107px;
    }

    @media #{$lg} {
      top: 84px;
    }

    @media #{$md} {
      position: relative;
      width: 100%;
      top: 0;
    }

    @media #{$xs} {
      position: relative;
      width: 100%;
      top: 0;
    }

    &.hero-img-2 {
      top: 0;
    }

    .hero-img-right {
      position: relative;

      @media #{$md} {
        position: static;
      }
      @media #{$xs} {
        position: static;
      }

      img {
        width: 100%;
      }
      // .hero-image {
      //   border-radius: 25% 0 0 25%;
      //   clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
      // }
    }

    img.dot-shape {
      position: absolute;
      left: -60px;
      bottom: 60px;
      z-index: 99;
    }
  }

  .video-btn {
    position: absolute;
    left: -40px;
    top: 50%;
    @include transform(translateY(-50%));

    @media #{$md} {
      left: 50%;
      @include transform(translate(-50%));
    }
    @media #{$xs} {
      left: 50%;
      @include transform(translate(-50%));
    }

    a {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $theme-color;
      @include transition(0.3s);
      @include box-shadow(0px 0px 20px rgba(#aabdf9, 0.45));

      &:hover {
        @include box-shadow(0px 0px 50px rgba(#aabdf9, 0.65));
      }
    }
  }

  &.hero-section-2 {
    .hero-content-wrapper {
      padding-bottom: 200px;

      @media #{$laptop} {
        padding: 150px 0;
      }
      @media #{$lg} {
        padding: 150px 0;
      }
      @media #{$md} {
        padding: 90px 0;
      }
      @media #{$xs} {
        padding: 90px 0 60px;
      }
    }

    .border-btn {
      &:hover {
        background: rgba($theme-color, 0.7);
        color: $white;
      }
    }
  }
}

.hero-section-style2 {
  padding-bottom: 140px;
}

// $color_1: #012970;
// $color_2: #444444;
// $color_3: #fff;

// .product-hero-section {
//   width: 100%;
//   height: 100vh;
//   background: url(assets/images/hero/hero-bg.webp) top center no-repeat;
//   background-size: cover;

//   h1 {
//     margin: 0;
//     color: $heading-color;
//     font-size: 48px;
//     font-weight: 700;
//   }

//   h2 {
//     margin: 2rem 0;
//     color: $theme-color;
//     font-size: 26px;
//   }

//   p {
//     margin-bottom: 3rem;

//     color: $body-color;
//     font-size: 18px;
//     line-height: 26px;

//     opacity: 0.8;
//   }

//   .hero-img {
//     text-align: right;
//   }

//   .hero-content-control {
//     margin-top: 30px;
//   }

//   @media #{$laptop} {
//     background-attachment: fixed;
//   }

//   @media #{$lg} {
//     height: auto;
//     padding: 120px 0 60px 0;
//     .hero-img {
//       text-align: center;
//       margin-top: 80px;
//       img {
//         width: 80%;
//       }
//     }
//   }

//   @media #{$xs} {
//     text-align: center;
//     h1 {
//       font-size: 32px;
//     }
//     h2 {
//       font-size: 24px;
//     }
//     .hero-img {
//       img {
//         width: 100%;
//       }
//     }
//   }
// }

// .scan-page-hero-section-wrapper {
//   position: relative;
//   z-index: 3;

//   .clip-bg {
//     clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 89%);

//     @media #{$lg} {
//       clip-path: polygon(0% 8%, 100% 2%, 100% 98%, 0 92%);
//     }
//     @media #{$md} {
//       clip-path: polygon(0% 8%, 100% 2%, 100% 98%, 0 92%);
//     }
//     @media #{$xs} {
//       clip-path: polygon(0% 8%, 100% 2%, 100% 98%, 0 92%);
//     }

//     &:after {
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       z-index: -1;
//       background: linear-gradient(
//         to right,
//         rgba(111, 88, 232, 0.8) 0%,
//         #3763eb 100%
//       );
//     }
//   }
//   .section-content {
//     .section-title {
//       h2 {
//         margin-bottom: 25px;
//       }

//       p {
//         margin-bottom: 40px;
//       }
//     }

//     .border-btn {
//       border-color: $white;
//       color: $white;

//       &:hover {
//         background: rgba($white, 0.2);
//       }
//     }
//   }
// }
