/*===========================
    01. Common CSS
===========================*/

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;

  color: var(--#{$prefix}body-color);
  background-color: var(--#{$prefix}body-bg);
  overflow-x: hidden;

  // font-family: var(--#{$prefix}font-body);
  font-family: "Museo", sans-serif;
  font-size: var(--#{$prefix}body-font-size);
  font-weight: var(--#{$prefix}body-font-weight);
  line-height: var(--#{$prefix}body-line-height);
  font-style: normal;

  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}

i,
span {
  outline: 0;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: $primary;
    @include transition(0.3s);
  }
}

.navbar-toggler:focus,
a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  @include box-shadow(none);
}

button {
  cursor: pointer;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

// Analog: .img-fluid
img:not([draggable]),
embed,
object,
video {
  height: auto;
  max-width: 100%;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  user-select: none;
  vertical-align: middle;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

// [class*="col-"] {
//   padding-top: 2rem;
//   padding-bottom: 2rem;
// }

// .container {
//   @media #{$sm} {
//     width: 450px;
//   }
// }

// others common css here
.alert-link {
  font-weight: 600;
}
.alert h4 {
  margin-bottom: 10px;
}

// .gray-bg {
//   background: $gray;
// }

.bg-gray {
  --#{$prefix}bg-opacity: 1;
  // background-color: rgba($color, var(--#{$prefix}bg-opacity)) !important;
  background-color: $gray-500;
}

.bg-secondary {
  --#{$prefix}bg-opacity: 1;
  background-color: #f3f6ff !important;
}

.equal,
.equal > div[class*="col-"] {
  display: flex;
  flex: 1 0 auto;
}

// ============ Margin and Padding ===============

// Margin-top
@for $i from 1 through 45 {
  .mt-#{5 * $i} {
    margin-top: 5px * $i;
  }
}
// Margin-bottom
@for $i from 1 through 45 {
  .mb-#{5 * $i} {
    margin-bottom: 5px * $i;
  }
}
// Padding-top
@for $i from 1 through 45 {
  .pt-#{5 * $i} {
    padding-top: 5px * $i;
  }
}
// Padding-bottom
@for $i from 1 through 45 {
  .pb-#{5 * $i} {
    padding-bottom: 5px * $i;
  }
}

// NOTE : https://habr.com/ru/companies/ruvds/articles/865580/
@media (prefers-reduced-motion: reduce) {
  @view-transition {
    navigation: none;
  }
}

// scroll-top
.scroll-top-btn {
  position: fixed;
  bottom: 90px;
  right: 30px;
  z-index: 9;

  display: block;

  width: 45px;
  height: 45px;

  color: $white;
  background: $theme-color;

  text-align: center;

  font-size: 20px;
  line-height: 45px;

  border-radius: 5px;

  cursor: pointer;

  animation: animation2 2s ease-in-out;
  @include transition(0.4s);

  &:hover {
    background: rgba($theme-color, 0.7);
    color: $white;
  }
}

@keyframes animation2 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes animation1 {
  0% {
    @include transform(translateY(0px));
    opacity: 0.29;
  }

  50% {
    @include transform(translateY(-700px));
    opacity: 0;
  }

  100% {
    @include transform(translateY(0px));
    opacity: 0;
  }
}

/*===== All Preloader Style =====*/

.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: $white;
  z-index: 99999;

  & .loader {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    // & .ytp-spinner {
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   width: 64px;
    //   margin-left: -32px;
    //   z-index: 18;
    //   pointer-events: none;

    //   & .ytp-spinner-container {
    //     pointer-events: none;
    //     position: absolute;
    //     width: 100%;
    //     padding-bottom: 100%;
    //     top: 50%;
    //     left: 50%;
    //     margin-top: -50%;
    //     margin-left: -50%;
    //     @include animation(ytp-spinner-linspin 1568.23529647ms linear infinite);

    //     & .ytp-spinner-rotator {
    //       position: absolute;
    //       width: 100%;
    //       height: 100%;
    //       @include animation(
    //         ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    //           both
    //       );

    //       & .ytp-spinner-left {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         bottom: 0;
    //         overflow: hidden;
    //         right: 50%;
    //       }

    //       & .ytp-spinner-right {
    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         bottom: 0;
    //         overflow: hidden;
    //         left: 50%;
    //       }
    //     }
    //   }
    // }

    // & .ytp-spinner-circle {
    //   box-sizing: border-box;
    //   position: absolute;
    //   width: 200%;
    //   height: 100%;
    //   border-style: solid;
    //   /* Spinner Color */
    //   border-color: $theme-color $theme-color $border-color;
    //   border-radius: 50%;
    //   border-width: 6px;
    // }

    // & .ytp-spinner-left {
    //   & .ytp-spinner-circle {
    //     left: 0;
    //     right: -100%;
    //     border-right-color: $border-color;
    //     @include animation(
    //       ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    //         both
    //     );
    //   }
    // }

    // & .ytp-spinner-right {
    //   & .ytp-spinner-circle {
    //     left: -100%;
    //     right: 0;
    //     border-left-color: $border-color;
    //     @include animation(
    //       ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
    //     );
    //   }
    // }
  }
}

/* Preloader Animations */

// @-webkit-keyframes ytp-spinner-linspin {
//   to {
//     @include transform(rotate(360deg));
//   }
// }

// @keyframes ytp-spinner-linspin {
//   to {
//     @include transform(rotate(360deg));
//   }
// }

// @-webkit-keyframes ytp-spinner-easespin {
//   12.5% {
//     @include transform(rotate(135deg));
//   }
//   25% {
//     @include transform(rotate(270deg));
//   }
//   37.5% {
//     @include transform(rotate(405deg));
//   }
//   50% {
//     @include transform(rotate(540deg));
//   }
//   62.5% {
//     @include transform(rotate(675deg));
//   }
//   75% {
//     @include transform(rotate(810deg));
//   }
//   87.5% {
//     @include transform(rotate(945deg));
//   }
//   to {
//     @include transform(rotate(1080deg));
//   }
// }

// @keyframes ytp-spinner-easespin {
//   12.5% {
//     @include transform(rotate(135deg));
//   }
//   25% {
//     @include transform(rotate(270deg));
//   }
//   37.5% {
//     @include transform(rotate(405deg));
//   }
//   50% {
//     @include transform(rotate(540deg));
//   }
//   62.5% {
//     @include transform(rotate(675deg));
//   }
//   75% {
//     @include transform(rotate(810deg));
//   }
//   87.5% {
//     @include transform(rotate(945deg));
//   }
//   to {
//     @include transform(rotate(1080deg));
//   }
// }

// @-webkit-keyframes ytp-spinner-left-spin {
//   0% {
//     @include transform(rotate(130deg));
//   }
//   50% {
//     @include transform(rotate(-5deg));
//   }
//   to {
//     @include transform(rotate(130deg));
//   }
// }

// @keyframes ytp-spinner-left-spin {
//   0% {
//     @include transform(rotate(130deg));
//   }
//   50% {
//     @include transform(rotate(-5deg));
//   }
//   to {
//     @include transform(rotate(130deg));
//   }
// }

// @-webkit-keyframes ytp-right-spin {
//   0% {
//     @include transform(rotate(-130deg));
//   }
//   50% {
//     @include transform(rotate(5deg));
//   }
//   to {
//     @include transform(rotate(-130deg));
//   }
// }

// @keyframes ytp-right-spin {
//   0% {
//     @include transform(rotate(-130deg));
//   }
//   50% {
//     @include transform(rotate(5deg));
//   }
//   to {
//     @include transform(rotate(-130deg));
//   }
// }

// .text-left {
//   text-align: left !important;
// }

// .text-right {
//   text-align: right !important;
// }

// .text-center {
//   text-align: center !important;
// }

// .text-primary {
//   color: #0d6efd !important;
// }

// .text-secondary {
//   color: #6c757d !important;
// }

// .text-lowercase {
//   text-transform: lowercase !important;
// }

// .text-uppercase {
//   text-transform: uppercase !important;
// }

// .text-capitalize {
//   text-transform: capitalize !important;
// }

// @media (min-width: 768px) {
//   .text-md-left {
//     text-align: left !important;
//   }

//   .text-md-right {
//     text-align: right !important;
//   }

//   .text-md-center {
//     text-align: center !important;
//   }
// }

// @media (min-width: 992px) {
//   .text-lg-left {
//     text-align: left !important;
//   }

//   .text-lg-right {
//     text-align: right !important;
//   }

//   .text-lg-center {
//     text-align: center !important;
//   }
// }

// @media (min-width: 1200px) {
//   .text-xl-left {
//     text-align: left !important;
//   }

//   .text-xl-right {
//     text-align: right !important;
//   }

//   .text-xl-center {
//     text-align: center !important;
//   }
// }
