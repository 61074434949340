.main-intro-card__btn {
  bottom: 24px;
  left: 24px;
  pointer-events: none;
  position: absolute;
}

.card-hover-btn:focus .card-hover-btn__text {
  max-width: 251px;
  padding-left: 8px;
}

.card-hover-btn_active .card-hover-btn__text {
  max-width: 251px;
  padding-left: 8px;
}

.card-hover-btn__text {
  color: #fff;
  max-width: 0;
  overflow: hidden;
  padding-right: 0;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.main-intro-card {
  border-radius: 16px;
  margin: auto;
  position: relative;
  width: 100%;
}

@media (min-width: 1280px) {
  .main-intro-card {
    border-radius: 20px;
  }
}

.main-intro-card__img {
  height: 100%;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

.main-intro-card__img:hover {
  transform: scale(1.05);
}
