.vertical-line {
  height: 610px;
  margin: 0 -5px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 950px) {
  .vertical-line {
    margin-bottom: -10px;
  }
}

.vertical-line__top {
  width: 100%;
  height: 100px;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 1;
  background: url(//gptchat.ru/wp-content/themes/aijora/css/../img/png/gradient-1.png)
    repeat-x;
}

.vertical-line__bottom {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: -10px;
  left: 0;
  z-index: 1;
  background: url(//gptchat.ru/wp-content/themes/aijora/css/../img/png/gradient-2.png)
    repeat-x;
}

.vertical-line__list {
  position: relative;

  width: 33.333%;
  min-width: 0;
  margin: 0 5px;

  animation: v-line-1 20s infinite linear;

  &:nth-child(2) {
    -webkit-animation-name: v-line-2;
    animation-name: v-line-2;
    top: -573px;
  }

  &:nth-child(3) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}

.vertical-line__item {
  position: relative;

  height: 181px;
  margin: 0 0 10px;
  // padding: 16px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  // justify-content: space-between;
  justify-content: center;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  background: #f9fafb;
  border: 1px solid #e8e9ed;
  border-radius: 24px;
}

.vertical-line__logo-wrap {
  width: 49px;
  height: 49px;
  overflow: hidden;
}

.vertical-line__logo {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.vertical-line__content {
  width: 100%;
}

.vertical-line__title {
  font: 600 18px/25px "Inter";
  color: #000;
  letter-spacing: -0.36px;
}

.vertical-line__subtitle {
  font: 500 16px/22px "Inter";
  color: #7e858f;
  letter-spacing: -0.36px;
}

@-webkit-keyframes v-line-1 {
  100% {
    transform: translateY(-573px);
  }
}

@keyframes v-line-1 {
  100% {
    transform: translateY(-573px);
  }
}

@-webkit-keyframes v-line-2 {
  100% {
    transform: translateY(573px);
  }
}

@keyframes v-line-2 {
  100% {
    transform: translateY(573px);
  }
}

.bg-image-wrapper {
  // display: inline-block;
  // margin-top: 25px;
  position: relative;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    // v1
    min-width: 100%;
    min-height: 100%;
    flex-shrink: 0;
    // V2
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
  }
}
