/*===========================
    12. Contact CSS 
===========================*/

.section-contacts-style4 {
  .position-relative .shape.rellax + figure {
    position: relative;
    z-index: 2;
  }

  .shape.rellax {
    position: absolute;
    z-index: 1;
  }

  .bg-soft-primary {
    background-color: #edf2fc;
  }
}

.section-contacts,
.call-to-action-section {
  position: relative;
  z-index: 3;

  &.cta-bg {
    clip-path: polygon(0 0, 100% 12%, 100% 88%, 0 100%);
    z-index: 3;

    @media #{$md} {
      clip-path: polygon(0 3%, 100% 12%, 100% 88%, 0 97%);
    }
    @media #{$xs} {
      clip-path: polygon(0 3%, 100% 12%, 100% 88%, 0 97%);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: linear-gradient(
        to left,
        rgba(111, 88, 232, 0.18) 0%,
        #3763eb 100%
      );
    }
  }

  .contact-item-wrapper {
    .contact-item {
      display: flex;
      border: 1px solid $border-color;
      border-radius: 10px;
      background: $white;
      margin-bottom: 30px;
      padding: 20px 30px;
      @include transition(0.3s);

      &:hover {
        @include box-shadow(0px 0px 30px rgba(#d7e0fc, 0.45));

        .contact-icon {
          @include box-shadow(0px 0px 30px rgba(#2e57d9, 0.45));
        }
      }

      @media #{$laptop} {
        padding: 20px;
      }
      @media #{$md} {
        flex-direction: column;
      }
      @media #{$xs} {
        flex-direction: column;
      }

      .contact-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $theme-bg;
        color: $white;
        @include transition(0.3s);
      }
      .contact-content {
        margin-left: 25px;

        @media #{$laptop} {
          margin-left: 20px;
        }
        @media #{$md} {
          margin-left: 0px;
          margin-top: 20px;
        }
        @media #{$xs} {
          margin-left: 0px;
          margin-top: 20px;
        }

        h4 {
          font-size: 20px;
          color: $theme-color;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.comment-form-wrapper,
.contact-form-wrapper {
  padding: 50px 40px;
  background: $white;
  border: 1px solid $border-color;
  margin-left: 0px;
  border-radius: 10px;
  @include transition(0.3s);

  &:hover {
    @include box-shadow(0px 0px 30px rgba(#d7e0fc, 0.45));
  }

  //@media #{$laptop} {
  //  margin-left: 30px;
  //}

  .comment-form,
  .contact-form {
    input,
    textarea {
      width: 100%;

      padding: 18px 25px;
      //margin-bottom: 25px;

      border-radius: 30px;
      border: 1px solid $border-color;

      @include transition(0.3s);

      @media #{$xs} {
        padding: 12px 25px;
      }

      &:focus {
        border-color: $theme-color;
      }
    }

    textarea {
      border-radius: 18px;
    }

    .theme-btn {
      font-weight: 500;
      padding: 18px 90px;
    }

    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-select ~ label::after {
      margin-left: 1em;

      @media #{$xs} {
        margin-left: 12px;
      }
    }
  }
}

.order-form-modal {
  .modal-content {
    .modal-body {
      padding: 0;
    }
    .form-header {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
    }
  }
}
