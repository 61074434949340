.coverage {
  padding-top: 120px;

  .map {
    position: relative;

    img {
      width: 100%;
    }

    .pointer {
      width: 8px;
      height: 8px;
      background: #212a34;
      border-radius: 50%;
      position: absolute;
      z-index: 2;

      &.pointer-1 {
        top: 25%;
        left: 75%;
      }

      &.pointer-2 {
        top: 50%;
        left: 75%;
      }

      &.pointer-3 {
        top: 51%;
        left: 72%;
      }

      &.pointer-4 {
        top: 40%;
        left: 20%;
      }

      &.pointer-5 {
        top: 78%;
        left: 85%;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #212a34;
        animation: map 2s linear infinite;
        border-radius: 50%;
        transition: 0.3s;
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #212a34;
        animation: map 1s linear infinite;
        border-radius: 50%;
        transition: 0.3s;
        z-index: -1;
      }

      .txt {
        position: absolute;
        display: inline-block;
        bottom: 300%;
        left: 50%;
        transform: translateX(-50%);
        background: #e9f1ff;
        border: 1px solid #999;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        text-transform: uppercase;
        padding: 0 15px;
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;

        &:after {
          content: "";
          position: absolute;
          top: 83%;
          left: 50%;
          width: 10px;
          height: 10px;
          background: #e9f1ff;
          transform: translateX(-50%) rotate(45deg);
          border: 1px solid transparent;
          border-right-color: #999;
          border-bottom-color: #999;
        }
      }

      &:hover .txt {
        bottom: 200%;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@keyframes map {
  0% {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  100% {
    top: -150%;
    left: -150%;
    width: 400%;
    height: 400%;
    opacity: 0;
  }
}
