/*===========================
    13. Footer CSS 
===========================*/

.ms-n9 {
  margin-left: -11rem !important;
}
.mt-n8 {
  margin-top: -8rem !important;
}

.footer {
  position: relative;
  background: $gray-900;
  z-index: 2;

  .footer-text {
    color: $white;
    opacity: 0.8;

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .footer-widget.style2 {
    // margin-top: 40px;
    .footer-widget-title {
      // margin-bottom: 3rem;

      color: $white;

      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .footer-links {
    li {
      margin-bottom: 0.5rem;
    }

    a {
      @extend .footer-text;

      &:hover {
        color: $primary;
      }
    }
  }

  .footer-widget-links-list {
    li {
      a {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      a {
        i,
        svg {
          position: relative;
          top: 2px;
          font-size: 14px;
        }
      }
    }
  }

  .footer-widget-links-list.footer-menu {
    a {
      &:hover {
        margin-left: 4px;
      }
    }
  }

  .footer-contact-single {
    i,
    svg {
    }

    p {
      margin-left: 0.5rem;
    }
  }

  .footer-bottom {
    .footer-bottom-links {
      text-align: left;

      ul li {
        display: inline-block;
        margin-right: 40px;
      }
    }

    p {
      // line-height: 28px;
    }
  }

  .footer-widget.style1 {
    .footer-desc {
      line-height: 30px;
    }

    h4 {
      // margin-bottom: 35px;
      font-weight: 500;

      @media #{$lg} {
        font-size: 22px;
      }
    }

    ul.footer-links {
      li {
        a {
          // color: $body-color;
          display: block;
          // line-height: 35px;
          transition: all 0.3s;

          &:hover {
            color: $theme-color;
          }
        }
      }
    }

    .footer-menu {
      ul {
        // padding-left: 20px;
        li {
          list-style: none;
        }
      }
    }

    ul {
      //padding-left: 20px;
      li {
        list-style: none;
      }
    }

    .footer-contact {
      li {
        p,
        a {
          line-height: 1.8rem;
          // line-height: 35px;
        }
      }
    }

    .footer-desc {
      font-weight: 500;
      font-size: 24px;

      @media #{$lg} {
        font-size: 36px;
      }
    }
  }

  .copyright-area {
    position: relative;
    z-index: 2;
    //border-top: 2px solid rgba($body-color, 0.44);
    padding: 25px 0;

    // font-size: 1rem;

    font-size: 0.725rem;
    line-height: 1.2;

    p {
      text-align: right;

      // a {
      //   color: $body-color;

      //   &:hover {
      //     color: $theme-color;
      //   }
      // }
    }
  }
}
