$image-border-radius: $border-radius-xxl;

.section-feature-of-free-help-style1 {
  .image-cards {
    &.image-1 {
      border-radius: $border-radius-xxl 0 0 $border-radius-xxl;
    }
    &.image-2 {
      border-radius: 0 $border-radius-xxl 0 0;
    }
    &.image-3 {
      border-radius: 0 0 $border-radius-xxl 0;
    }
  }
}
