.section-call-to-action-style4 {
  blockquote {
    background-color: transparent;
    p {
      color: #f1f4f8;
    }
  }
  .blockquote-details {
    display: flex;
    align-items: center;
    text-align: left;

    p {
      color: #f1f4f8;
      @include font-size($font-size-base);
    }
    .info {
      padding-left: 1rem;
    }
    .icon-img img {
      width: 3.5rem !important;
    }
  }
}

.section-testimonial-style2 {
  blockquote {
    background-color: transparent;
    p {
      // color: #f1f4f8;
    }
  }
  .blockquote-details {
    display: flex;
    align-items: center;
    text-align: left;

    p {
      // color: #f1f4f8;
      @include font-size($font-size-base);
    }
    .info {
      padding-left: 1rem;
    }
    .icon-img img {
      width: 3.5rem !important;
    }
  }
}

$color_1: var(--#{$prefix}white-color);
$font-family_1: var(--#{$prefix}default-font);

/************************************/
/***      10. How It Work css	  ***/
/************************************/
.how-it-work {
  background: var(--#{$prefix}secondary-color);
  padding: 100px 0;
}
.how-work-step-box {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.how-work-step-item {
  position: relative;
  width: calc(25% - 30px);
  background: url("../../assets/images/new/how-work-step-arrow-1.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 60% auto;
  display: flex;
  flex-wrap: wrap;
  &:nth-child(even) {
    background: url("../../assets/images/new/how-work-step-arrow-2.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 60% auto;
    flex-direction: column-reverse;
    .how-work-step-no {
      margin: 30px 0 0 0;
    }
  }

  &:last-child,
  &:nth-child(4n + 4) {
    background: transparent;
  }

  &:hover {
    .how-work-step-no {
      &::before {
        transform: scale(1);
      }
    }
  }
}

.how-work-step-no {
  position: relative;
  background-color: $primary;
  border-radius: 8px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--#{$prefix}color);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: all 0.4s ease-in-out;
    z-index: 0;
  }
  h3 {
    position: relative;
    font-family: $font-family_1;
    font-size: 50px;
    font-weight: 500;
    text-transform: capitalize;
    color: $color_1;
    z-index: 1;
  }
}

.how-work-step-content {
  width: 100%;
  // max-width: 230px;
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
}

// .input-group {
//   // $input-group-padding-y: $input-group-padding-y;
//   // $input-group-padding-x: $input-group-padding-x;
//   // $input-group-border-color: #{$input-group-border-color};
//   // $input-group-border-width: #{$input-border-width};
//   // $input-group-border-radius: #{$input-group-border-radius};

//   padding: $input-group-padding-y $input-group-padding-x;
//   border: 1px solid $input-border-color;
//   @include border-radius(var(--#{$prefix}input-group-border-radius));
//   .form-control,
//   .form-select {
//     border-color: transparent !important; // stylelint-disable-line declaration-no-important
//     border-radius: 0 !important; // stylelint-disable-line declaration-no-important
//   }

//   [class^="#{$icon-prefix}"],
//   [class*=" #{$icon-prefix}"] {
//     font-size: 1.25em;
//   }

//   .btn {
//     // --#{$prefix}btn-padding-y: #{$btn-padding-y};
//     // --#{$prefix}btn-padding-x: #{$btn-padding-x};
//     // --#{$prefix}btn-border-radius: #{$btn-border-radius};

//     padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
//     &:not(.rounded-pill) {
//       @include border-radius(
//         var(--#{$prefix}btn-border-radius) !important
//       ); // stylelint-disable-line declaration-no-important
//     }
//   }
// }

// // Sizing

// .input-group-lg {
//   --#{$prefix}input-group-border-radius: #{$input-group-border-radius-lg};

//   .btn {
//     --#{$prefix}btn-padding-y: #{$btn-padding-y-lg};
//     --#{$prefix}btn-padding-x: #{$btn-padding-x-lg};
//     --#{$prefix}btn-border-radius: #{$btn-border-radius-lg};
//   }
// }
// .input-group-sm {
//   --#{$prefix}input-group-border-radius: #{$input-group-border-radius-sm};

//   .btn {
//     --#{$prefix}btn-padding-y: #{$btn-padding-y-sm};
//     --#{$prefix}btn-padding-x: #{$btn-padding-x-sm};
//     --#{$prefix}btn-border-radius: #{$btn-border-radius-sm};
//   }
// }

// // Textual addon

// .input-group-text {
//   border-radius: 0 !important; // stylelint-disable-line declaration-no-important
//   + .form-control,
//   + .form-select,
//   + .input-group-text {
//     padding-left: 0;
//   }
// }
// .form-control + .input-group-text,
// .form-select + .input-group-text {
//   padding-left: 0;
// }

//----------------------------------------------------------//
//	CONTROLS
//----------------------------------------------------------//
.swiper-controls {
  position: absolute !important;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .swiper-navigation {
    pointer-events: all;
    .swiper-button {
      background: rgba(var(--#{$prefix}white-rgb), 0.7);
      // color: $main-dark;
      border: 0;
      box-shadow: $box-shadow-sm;
      width: 2.2rem;
      height: 2.2rem;
      line-height: inherit;
      border-radius: 100%;
      text-shadow: none;
      // @include transition($link-transition);
      &:hover {
        background: rgba(var(--#{$prefix}white-rgb), 0.9);
      }
      &:focus {
        outline: 0;
      }
      &:after {
        // font-family: $font-family-unicons;
        @include font-size(1.2rem);
      }
      &.swiper-button-prev {
        left: 1rem;
        &:after {
          // content: $icon-prev;
        }
      }
      &.swiper-button-next {
        right: 1rem;
        &:after {
          // content: $icon-next;
        }
      }
      &.swiper-button-disabled {
        background: rgba(var(--#{$prefix}white-rgb), 0.7);
      }
    }
  }
  .swiper-pagination {
    bottom: -2.8rem;
    position: absolute !important;
    pointer-events: all;
    .swiper-pagination-bullet {
      -webkit-backface-visibility: visible;
      // @include transition($link-transition);
      position: relative;
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.3rem;
      padding: 0;
      background: red;
      opacity: 0.5;
      border-radius: 100%;
      border: 3px solid transparent;
      transform: scale(0.6);
      &:hover {
        transform: scale(1);
      }
      &:focus {
        outline: 0;
      }
      &.swiper-pagination-bullet-active {
        transform: scale(1);
        background: none;
        border-color: red;
      }
    }
  }
}
.swiper-container {
  -webkit-user-select: none;
  position: relative;
  z-index: 10 !important;
  &.clients .swiper {
    display: flex;
    align-items: center;
  }
  .swiper {
    img {
      width: 100%;
      height: auto;
    }
  }
  &.nav-color .swiper-button,
  &.nav-color .swiper-slide figure .item-link {
    background: rgba(var(--#{$prefix}primary-rgb), 0.9) !important;
    color: var(--#{$prefix}white) !important;
    &.swiper-button-disabled {
      background: rgba(var(--#{$prefix}primary-rgb), 0.7) !important;
    }
    &:hover {
      background: var(--#{$prefix}primary) !important;
    }
  }
  &.nav-dark .swiper-button,
  &.nav-dark .swiper-slide figure .item-link {
    // background: $lightbox-icon-bg !important;
    // color: $lightbox-icon-color !important;
    &.swiper-button-disabled {
      // background: rgba($black, 0.1) !important;
    }
    &:hover {
      // background: $lightbox-icon-hover-bg !important;
    }
  }
  &.nav-bottom {
    .swiper-navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      transform: none;
      position: absolute !important;
      bottom: -3.2rem;
      left: 0;
      width: 100%;
      .swiper-button {
        top: auto;
        left: auto;
        right: auto;
        bottom: 0;
        position: relative;
        margin: 0 0.2rem;
      }
      + .swiper-pagination {
        bottom: -5.2rem;
      }
    }
    &.nav-far .swiper-navigation {
      bottom: -4.1rem;
    }
    &.nav-start .swiper-navigation {
      text-align: left;
      justify-content: flex-start;
      .swiper-button {
        margin: 0 0.4rem 0 0;
      }
    }
  }
  &.dots-over {
    .swiper-pagination {
      bottom: 0.6rem;
      .swiper-pagination-bullet {
        background: var(--#{$prefix}white);
        opacity: 1;
        box-shadow: $box-shadow-sm;
        &.swiper-pagination-bullet-active {
          background: none;
          border-color: var(--#{$prefix}white);
        }
      }
    }
  }
  &.dots-start .swiper-pagination {
    text-align: left;
    justify-content: flex-start;
  }
  &.dots-light .swiper-pagination .swiper-pagination-bullet {
    background: var(--#{$prefix}white);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: none;
      border-color: var(--#{$prefix}white);
    }
  }
  &.dots-dark .swiper-pagination .swiper-pagination-bullet {
    // background: $lightbox-icon-bg;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: none;
      // border-color: $lightbox-icon-bg;
    }
  }
  &.dots-light.dots-light-75 .swiper-pagination .swiper-pagination-bullet {
    opacity: 0.75;
  }
  &.dots-closer .swiper-pagination {
    bottom: -2rem;
  }
}
//----------------------------------------------------------//
//	OVERLAY
//----------------------------------------------------------//
.swiper-slide {
  &.bg-overlay:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($gray-900, 0.5);
  }
  &.rounded.bg-overlay:before {
    border-radius: $border-radius;
  }
  &.bg-overlay-300:before {
    background: rgba($gray-900, 0.3);
  }
  &.bg-overlay-400:before {
    background: rgba($gray-900, 0.4);
  }
  &.bg-overlay [class*="container"] > *,
  &.bg-overlay .caption-wrapper > * {
    position: relative;
    z-index: 3;
  }
  .caption-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    .caption {
      display: flex;
    }
  }
}
@media (max-width: 767px) {
  .caption-wrapper {
    display: none !important;
  }
}
root {
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: red;
}
