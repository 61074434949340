// $primary: #325d88 !default;
$background-color_1: $gray-300;

.section-about-us-style1 {
  // padding: 100px 0;

  .about-us-content {
    margin-left: 25px;
  }

  .about-us-images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    gap: 30px;
  }

  .about-img-1 {
    width: calc(40% - 15px);
  }

  .about-img-2 {
    width: calc(60% - 15px);
  }

  .about-img-1 img,
  .about-img-2 img {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .about-img-1 {
    figure {
      display: block;
    }

    img {
      aspect-ratio: 1 / 1.75;
      object-fit: cover;
    }
  }

  .about-img-2 {
    figure {
      display: block;
      mask-image: url(../../../assets/images/new/about-img-2-bg-shape.svg);
      background-image: url(../../../assets/images/new/about-img-2-bg-shape.svg);
      mask-size: cover;
      mask-position: center center;
      mask-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    img {
      aspect-ratio: 1 / 1.75;
      object-fit: cover;
    }
  }

  .about-customer-box {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f9f9f9;
    border-radius: 20px;
    // width: 100%;
    // max-width: 370px;
    width: calc(60% - 15px);
    padding: 30px;
  }

  .about-customer-rating {
    margin-bottom: 15px;

    i {
      font-size: 14px;
      color: $primary;
    }
  }

  .about-customer-content {
    margin-bottom: 15px;

    p {
      margin: 0;

      span {
        color: $primary;
      }
    }
  }

  .about-customer-box .customer-images {
    display: flex;
    align-items: center;
  }

  .customer-img {
    position: relative;
    display: inline-block;
    border: 2px solid $white;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -16px;
    width: 50px;
    height: 50px;
    z-index: 1;
    &:first-child {
      margin: 0;
    }
    figure {
      display: block;
    }
    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
  .customer-img.add-more {
    width: 52px;
    height: 52px;
    background-color: $background-color_1;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    p {
      // font-family: $font-family_1;
      color: $white;
      margin: 0;
    }
  }
  // .about-us-content-btn .btn-default.btn-highlighted {
  //   margin-left: 30px;
  // }
}
