.therapist-info-card,
.courses-details-info {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 1rem 1rem 3rem 1rem;
  // margin-top: 50px;

  &-image {
    text-align: center;
    position: relative;
  }

  .info {
    margin-bottom: 0;
    list-style-type: none;
    padding: 30px 15px;

    li {
      border-bottom: 1px solid #e5e5e7;
      //   color: $optional-color;
      //   font-size: $font-size;
      font-weight: 500;
      padding-top: 15px;
      padding-bottom: 15px;

      span {
        font-weight: 600;
        color: $black;
      }
    }
    &:first-child {
      padding-top: 0;
    }
  }

  .courses-btn-box {
    text-align: center;
  }
}

.therapist-info-card {
  border-radius: 20px;
  overflow: hidden;
  top: 100px;
  position: sticky;
}

.therapist-qualification-section_ {
  .nav-pills {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 30px;
    margin-top: 25px;

    li {
      a,
      button {
        position: relative;
        z-index: 1;
        padding: 0;
        text-transform: uppercase;
        background: transparent;
        padding-bottom: 15px;
      }

      &.active {
        a,
        button {
          background: transparent;
          color: #0cb8b6;
        }

        &::after {
          position: absolute;
          left: 0;
          bottom: -2px;
          content: "";
          height: 3px;
          width: 100%;
          background: #0cb8b6;
        }
      }

      margin-right: 25px;
    }
  }

  .info {
    a,
    button {
      margin-top: 10px;
    }

    h4 {
      margin-bottom: 25px;
      color: #666666;
      text-transform: uppercase;
    }
  }

  .nav-tabs {
    border-bottom: null;
  }
}

.tab-content_ {
  // margin-bottom: -10px;

  p:last-child {
    margin-bottom: 0;
  }
  li {
    // font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    padding: 10px;

    &:nth-child(odd) {
      background: #f4f4f4;
    }
  }
}

.details-info-top-wrap {
  padding: 2rem;
  border: 1px solid $border-color;
  border-radius: 15px;

  @include transition(0.3s);

  @media #{$sm} {
    padding: 1.25rem 1rem;
  }

  @media #{$xs} {
  }
}

//
// Extend from bootstrap
//

// Card

// .card {
//   border: 0rem;
//   box-shadow: $box-shadow-sm;
// }

// // Img left rounded for card
// .img-left-rounded {
//   border-radius: 0.5rem 0 0 0.5rem;
//   @media (max-width: 768px) {
//     border-radius: 0.5rem 0.5rem 0 0;
//   }
//   @media (min-width: 768px) and (max-width: 991.98px) {
//     border-radius: 0.5rem 0.5rem 0 0;
//   }
// }

// // Card header height
// .card-header-height {
//   height: 3.75rem;
// }

// // Card hover
// .card-hover {
//   transition: box-shadow 0.25s ease;
//   &:hover,
//   &:focus,
//   &.active {
//     box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//       0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
//   }
// }

// // Accordion
// .accordion {
//   > .card {
//     > .card-header {
//       margin-bottom: 0rem;
//     }
//   }
// }

// // card img size
// .card-img-size-600 {
//   width: 100%;
//   @media (min-width: 990px) {
//     width: 600px;
//   }
// }

// // card bordered
// .card-bordered {
//   border: 1px solid var(--#{$prefix}gray-200);
//   box-shadow: none;
// }

// .card-dashed-hover {
//   border-style: dashed !important;
//   transition: 0.5s ease;
//   &:hover {
//     border: 2px dashed var(--#{$prefix}primary) !important;
//   }
// }

// .card-hover-with-icon {
//   transition: box-shadow 0.25s ease;
//   cursor: pointer;
//   &:hover,
//   &:focus,
//   &.active {
//     box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//       0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
//     border-top-color: var(--#{$prefix}primary) !important;
//     transition: ease 1s;
//     .card-icon {
//       background-color: var(--#{$prefix}light-primary) !important;
//       color: var(--#{$prefix}primary) !important;
//     }
//   }
// }

// Card Lift

.card-lift {
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: $border-radius-lg;
  &:hover,
  &:focus,
  &.active {
    transform: translateY(-0.25rem);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }
}

// .card-border-primary {
//   border: 1px solid transparent;
//   transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
//     background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
//   &:hover {
//     border-color: $primary;
//     transform: translateY(-0.25rem);
//     box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//       0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
//   }
// }

// //card light primary
// .card-light-primary {
//   &:hover {
//     background-color: var(--#{$prefix}primary-bg-subtle);
//   }
// }

// mentor home hero mentor card
.mentor-card {
  width: 200px !important;
}
