.service_item {
  padding: 60px 60px 53px;

  box-shadow: var(--bs-box-shadow);
  transition: var(--bs-transition);
  background-color: var(--bs-white);
  border-radius: 24px;
}

.service_item:hover {
  transform: translateY(-6px);
}

.service_item .item_icon {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  margin-bottom: 30px;

  display: inline-flex;

  align-items: center;

  justify-content: center;
}

.service_item .item_title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
}

.service_item {
  padding: 45px;
}

.service_item .item_title {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 12px;
}

.service_item p {
  margin-bottom: 20px;
}
